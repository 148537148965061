import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { removeOrderDocument, replaceOrder } from '../../actions/actions_orders';
import { downloadFromS3Link } from '../../helpers/downloads';
import { convertToCamelCase } from '../../helpers';
import { Text, VStack, Center, Table, IconButton, HStack, Box } from '@chakra-ui/react';
import UploadDocumentForm from './UploadDocumentForm';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { ReactComponent as TrashOutlineIconSVG } from '../../assets/trash-outline-icon.svg';

const PurchaseOrderDocumentsTableTh = ({ children, ...rest }) => {
  return (
    <Table.ColumnHeader fontSize="12px" paddingY="12px" {...rest} fontFamily="body">
      {children}
    </Table.ColumnHeader>
  );
};

PurchaseOrderDocumentsTableTh.propTypes = {
  children: PropTypes.node.isRequired,
};
class PurchaseOrderDocuments extends PureComponent {
  static propTypes = {
    autoSave: PropTypes.bool,
    currentOrder: PropTypes.shape({
      loaded: PropTypes.bool,
      voyage: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          // Add other properties as needed
        })
      ),
    }),
    closeModal: PropTypes.func,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        docType: PropTypes.string,
        dateUploaded: PropTypes.string,
        url: PropTypes.string,
        user: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      })
    ),
    loading: PropTypes.bool,
    poDocTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    readonly: PropTypes.bool,
    stateForm: PropTypes.shape({
      form: PropTypes.shape({
        id: PropTypes.number,
      }),
      voyage: PropTypes.instanceOf(Object),
    }),
    token: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    error: PropTypes.string,
  };

  removeDocument = document => {
    // eslint-disable-next-line no-alert
    const remove = window.confirm(`Are you sure you want to remove ${document.docType}?`);
    const modifiedOrder = cloneDeep(this.props.stateForm.form);
    modifiedOrder.voyage = [{ ...this.props.stateForm.voyage }];
    // deleting is not end of order update functionality
    // modifiedOrder.editMode = true;
    if (remove) {
      if (document.id && this.props.autoSave) {
        this.props.dispatch(removeOrderDocument(modifiedOrder, document.id, this.props.token));
      } else {
        const index = modifiedOrder.documents.findIndex(doc => doc.url === document.url);
        modifiedOrder.documents.splice(index, 1);
        this.props.dispatch(replaceOrder(modifiedOrder));
      }
    }
  };

  render() {
    const {
      autoSave,
      closeModal,
      currentOrder,
      disabled,
      dispatch,
      documents,
      loading,
      poDocTypes,
      readonly,
      stateForm,
      token,
      error,
    } = this.props;

    return (
      <VStack gap="18px" align="stretch">
        <Text as="p" fontStyle="italic">
          Uploaded documents are not persisted until the form has been saved.
        </Text>
        {/* documents */}
        {!disabled && (
          <UploadDocumentForm
            token={token}
            error={error}
            {...convertToCamelCase({
              autoSave,
              closeModal,
              disabled,
              dispatch,
              loading,
              poDocTypes,
            })}
            currentOrder={currentOrder}
            stateForm={stateForm}
          />
        )}
        {documents.length ? (
          <Box borderRadius="6px" borderWidth="1px">
            <Table.Root variant="unstyled">
              <Table.Header backgroundColor="gray.100">
                <Table.Row>
                  <PurchaseOrderDocumentsTableTh>DOCUMENT TYPE</PurchaseOrderDocumentsTableTh>
                  <PurchaseOrderDocumentsTableTh>DATE UPLOADED</PurchaseOrderDocumentsTableTh>
                  <PurchaseOrderDocumentsTableTh>UPLOADED BY</PurchaseOrderDocumentsTableTh>
                  <PurchaseOrderDocumentsTableTh>ACTIONS</PurchaseOrderDocumentsTableTh>
                </Table.Row>
              </Table.Header>
              <Table.Body backgroundColor="white">
                {documents.map((document, indx) => (
                  <Table.Row key={indx}>
                    <Table.Cell> {document.docType}</Table.Cell>
                    <Table.Cell>{document.dateUploaded || 'Pending'}</Table.Cell>
                    <Table.Cell>
                      {document.user ? `${document.user.firstName} ${document.user.lastName}` : ''}
                    </Table.Cell>
                    <Table.Cell>
                      <HStack>
                        <IconButton variant="link" onClick={() => downloadFromS3Link(document.url)}>
                          <DownloadIcon />
                        </IconButton>
                        {!readonly && (
                          <IconButton
                            variant="ghost"
                            disabled={disabled}
                            hidden={disabled}
                            onClick={() => {
                              this.removeDocument(document);
                            }}
                          >
                            <TrashOutlineIconSVG />
                          </IconButton>
                        )}
                      </HStack>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </Box>
        ) : (
          <Center>
            <Text as="p">No documents</Text>
          </Center>
        )}
      </VStack>
    );
  }
}

export default PurchaseOrderDocuments;
