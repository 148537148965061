import React, { Component } from 'react';
import { Box, Text, Button, Flex, Spinner } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { IoMdCheckboxOutline } from 'react-icons/io';
// Local
import { addDocumentToOrder } from '../../actions/actions_orders';
import SelectField from '../basic/SelectField';

class UploadDocumentForm extends Component {
  static propTypes = {
    autoSave: PropTypes.bool,
    closeModal: PropTypes.func,
    currentOrder: PropTypes.shape({
      loaded: PropTypes.bool,
      voyage: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
    }),
    disabled: PropTypes.bool,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    poDocTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ).isRequired,
    stateForm: PropTypes.instanceOf(Object),
    token: PropTypes.string,
    error: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {
        fileKey: Math.random().toString(36),
        docType: false,
        file: false,
      },
    };
  }

  componentDidUpdate(nextProps, prevProps) {
    // TODO FixME, have a proper state management
    const { currentOrder, error } = this.props;
    const { loading } = this.state;
    if (currentOrder && currentOrder.loaded === prevProps.loading) {
      this.setState({ loading: false });
    }

    if (error && loading) {
      this.setState({ loading: false });
    }
  }

  onFieldChange = (e, fieldName) => {
    const copy = Object.assign({}, this.state);
    if (fieldName === 'docType') {
      copy.form[fieldName] = e ? e.value : null;
    } else {
      const [file] = e.target.files;
      copy.form[fieldName] = file;
    }
    this.setState(copy);
  };

  onUploadFile = file => {
    const copy = Object.assign({}, this.state);
    const [uploadedFile] = file;
    copy.form.file = uploadedFile;
    this.setState(copy);
  };

  onSave = () => {
    const { token, dispatch, stateForm, currentOrder, autoSave } = this.props;
    // Doc quick upload uses currentOrder prop.
    let po = { ...currentOrder };
    if (stateForm) {
      // When user is editing US/International order
      // Use component state with latest user changes.
      po = cloneDeep(stateForm.form);
      if (stateForm.voyage) {
        po.voyage = [{ ...stateForm.voyage }];
      }
    }
    dispatch(addDocumentToOrder(po, { ...this.state.form, saveToDB: autoSave }, token));
    this.updateState();
    this.setState({ loading: true });
    this.closeModal();
  };

  // generate fileKey and update state
  updateState = () => {
    this.setState(prevState => ({
      fileKey: Math.random().toString(36),
      form: {
        docType: prevState.form.docType,
        file: false,
      },
    }));
  };

  // if component used in modal let's close it after upload finished
  closeModal = () => {
    const { closeModal } = this.props;
    if (closeModal) {
      closeModal();
    }
  };

  render() {
    const { form, loading } = this.state;
    const { poDocTypes, disabled } = this.props;

    const docTypes = poDocTypes.map(item => {
      return { value: item.label || item.value, label: item.label || item.value };
    });
    return (
      <Box width="100%" gap="19px" display="flex" flexDirection="column">
        {/* <HStack spacing="19px"> */}
        <Box mt={5}>
          <SelectField
            name="select-doc-type"
            defaultValue={form.docType}
            options={!disabled && docTypes}
            placeholder="Select document type..."
            onChange={e => {
              this.onFieldChange(e, 'docType');
            }}
          />
        </Box>

        {loading ? (
          <Flex justifyContent="center" alignItems="center" height="40px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Dropzone
            className="document-dropzone document-dropzone--override"
            onDrop={this.onUploadFile}
            isDragActive={form.docType}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                fontSize="sm"
                paddingY={2}
                paddingX={4}
                mb={0}
                cursor={disabled ? 'not-allowed' : 'pointer'}
                color="white"
                fontWeight="medium"
                opacity={disabled ? 0.4 : 1}
                {...getRootProps()}
                border="2px dashed #2c333d"
                borderRadius="10px"
                textAlign="center"
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="30px"
                margin="20px"
                height="150px"
              >
                <Box bg="gray" borderRadius="10px" padding="8px 4px" minW="100px">
                  <input {...getInputProps()} />
                  <Text as="label" display="inline-flex" alignItems="center" gap={1}>
                    <span>Choose file</span>
                    <span hidden={!form.file}>
                      <IoMdCheckboxOutline />
                    </span>
                  </Text>
                </Box>
              </Box>
            )}
          </Dropzone>
        )}
        {/* {form.file && form.docType && ( */}
        <Flex justifyContent="end" mb={5}>
          <Button colorScheme="actionPrimary" fontWeight="bold" width="80px" onClick={this.onSave}>
            Save
          </Button>
        </Flex>
        {/* </HStack> */}
      </Box>
    );
  }
}

export default UploadDocumentForm;
