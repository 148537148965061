import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Input, VStack, Text, Flex } from '@chakra-ui/react';
import _ from 'lodash';

// Local
import Constants from '../../../../Constants';
import { fetchBuyerOrgUsers } from '../../../../actions/actions_users';
import { fetchConfigs } from '../../../../actions/actions_config';
import { fetchPackageWeights } from '../../../../actions/actions_package_weights';
import { capitalizeFirstLetter } from '../../../../functions';
import { constructBuyerOptions, getOr } from '../../../../helpers';
import { fetchEntitiesData } from '../../../../slices/masterData/entityManagerSlice';
import {
  analyzeBasePOForm,
  hasSelectedPackerAndGrinder,
  validateRequiredFields,
} from '../../../lib/PurchaseOrderFunctions';

import {
  filterOptionsByPurchasingOffice,
  formatOptionsFromFormFields,
  filterPackerPlantsByPackerId,
  filterEndUserOptionsByGrinder,
  filterGrinderOptionsByEndUser,
} from './helpers';
// Components
import InternationalOrderForm from '../InternationalOrderForm/InternationalOrderForm';
import InternationalOrderFormContainer from '../../../../containers/orders/InternationalOrderForm';
import SelectField from '../../../basic/SelectField';
import ContractSection from '../ContractSection';
import FormulaSection from '../FormulaSection';
import PortOptions from '../PortOptions';
import { getDefaultValueFromConfigs, getOptionsFromSettingsConfig } from '../../../../utils';

import { fetchSettingsConfig } from '../../../../actions/action_settings_config';
import { fetchFormFields } from '../../../../actions/actions_form_fields';
import DashboardSectionTile from '../../../core/DashboardSectionTile';
import CustomFormControl from '../../../core/CustomFormControl';

import IUnleashClassFlagProvider from '../../../unleash/UnleashClassFlagProvider';
import InternationalOrderDetails from '../edit-form/InternationalOrderDetails/InternationalOrderDetails';
import Comments from '../Comments/Comments';
import InternationalOrderFormEndSection from '../InternationalOrderForm/InternationalOrderFormEndSection';
import POFormFieldLabel from '../edit-form/POFormFieldLabel';
import { buyersSelector } from '../../../../reducers/reducer_users';
import { Alert } from '../../../ui/alert';

class CreatePurchaseOrderForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    token: PropTypes.string,
    grinders: PropTypes.arrayOf(PropTypes.object),

    packerPlants: PropTypes.arrayOf(PropTypes.object),
    packers: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({
      token: PropTypes.string,
      id: PropTypes.number,
    }),
    users: PropTypes.arrayOf(PropTypes.object),
    priceTypes: PropTypes.arrayOf(PropTypes.object),
    containers: PropTypes.arrayOf(PropTypes.object),
    unitsOfMeasure: PropTypes.arrayOf(PropTypes.object),
    voyage: PropTypes.shape({
      dischargePortId: PropTypes.number,
    }),
    ports: PropTypes.arrayOf(PropTypes.object),
    businessOffices: PropTypes.arrayOf(PropTypes.object),
    endUsers: PropTypes.arrayOf(PropTypes.object),
    configs: PropTypes.arrayOf(PropTypes.object),
    settingsConfig: PropTypes.arrayOf(PropTypes.object),
    packageWeights: PropTypes.arrayOf(PropTypes.Object),
    productTypes: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);

    const { user } = this.props;

    this.state = {
      formAttributes: analyzeBasePOForm({}),
      validationErrors: {
        requiredFields: { form: [], pos: [] },
      },
      packerOptions: [],
      grinderOptions: [],
      packerPlantOptions: [],
      filteredPackerOptions: [],
      filteredGrinderOptions: [],
      filteredPackerPlantOptions: [],

      grinderOptionsFIlteredByEndUser: null,
      endUserOptionsFilteredByGrinder: null,

      packer: null,
      errorMessage: null,
      grinder: null,
      packerPlant: null,
      endUser: null,
      baseForm: {
        singleIngredientForm: true,
        estId: '',
        priceType: false,
        packerPlantId: false,
        packerId: false,
        grinderUid: false,
        grinderPoNumber: '',
        buyerId: user.id,

        formulaName: false,
        formulaMarket: 'USDA National',
        formulaDay: false,
        formulaDaysAveraged: false,
        formulaBasis: -0.01,

        contractStartDate: false,
        contractEndDate: false,
        voyage: Constants.INITIAL_VOYAGE,

        buyIncoterms: '',

        productType: false,
        purchasingOffice: false,
        endUserId: null,
      },
    };
  }

  componentDidMount() {
    const { dispatch, user, configs, settingsConfig, productTypes } = this.props;
    if (configs.length === 0) {
      dispatch(fetchConfigs(user.token));
    }

    if (settingsConfig.length === 0) {
      dispatch(fetchSettingsConfig(user.token));
    }
    if (productTypes.length === 0) {
      dispatch(fetchFormFields(user.token, '?type=product_types'));
    }

    // Fetch entities
    dispatch(fetchEntitiesData());
    dispatch(fetchBuyerOrgUsers(user.token));
    dispatch(fetchPackageWeights());
  }

  componentDidUpdate(prevProps) {
    const { packers, grinders, packerPlants } = this.props;
    const { baseForm, packerOptions, packerPlantOptions, grinderOptions } = this.state;

    const purchasingOffice = baseForm?.purchasingOffice || Constants.BUSINESS_OFFICES.SYDNEY.value;

    // Prepare Packer options.
    // We filter on purchasingOffice here so that we don't have to do it on every render.
    if (packers.length !== prevProps.packers.length || (packers.length && isEmpty(packerOptions))) {
      const options = packers.map(el => ({
        value: el.id,
        label: el.name,
        purchasingOffice: el.purchasingOffice,
        address: el.address,
        id: el.id,
      }));
      const filtered = filterOptionsByPurchasingOffice(options, purchasingOffice);
      this.setState({ packerOptions: options, filteredPackerOptions: filtered });
    }

    // Prepare Grinder options:
    // We filter on purchasingOffice here so that we don't have to do it on every render.
    if (grinders.length !== prevProps.grinders.length || (grinders.length && isEmpty(grinderOptions))) {
      const options = grinders.map(el => ({
        value: el.uid,
        uid: el.uid,
        label: el.name,
        poStrategy: el.poStrategy,
        purchasingOffice: el.purchasingOffice,
        destinationCountry: el.destinationCountry,
        defaultPortId: el.defaultPortId,
      }));
      const filtered = filterOptionsByPurchasingOffice(options, purchasingOffice);
      this.setState({ grinderOptions: options, filteredGrinderOptions: filtered });
    }

    // Prepare Packer Plant Options:
    // We filter on purchasingOffice here so that we don't have to do it on every render.
    // We also filter on packer id, if available.
    if (packerPlants.length !== prevProps.packerPlants.length || (packerPlants.length && isEmpty(packerPlantOptions))) {
      const options = packerPlants.map(el => ({
        id: el.id,
        value: el.id,
        label: el.name,
        purchasingOffice: el.purchasingOffice,
        estId: el.est,
        packerId: el.packerId,
        originCountry: el.originCountry,
      }));
      const filtered = filterOptionsByPurchasingOffice(options, purchasingOffice);
      filtered.length == 0
        ? this.setState({ errorMessage: 'No establishment has purchasing_office configured' })
        : this.setState({ errorMessage: null });
      const sorted = filterPackerPlantsByPackerId(filtered, baseForm.packerId);
      this.setState({ packerPlantOptions: options, filteredPackerPlantOptions: sorted });
    }
  }

  clearFormData = () => {
    const cleanBase = {
      singleIngredientForm: true,
      estId: '',
      priceType: false,
      packerPlantId: false,
      packerId: false,
      grinderUid: false,

      buyerId: this.props.user.id,
      loadContainerDetails: '',

      formulaName: false,
      formulaMarket: 'USDA National',
      formulaDay: false,
      formulaDaysAveraged: false,
      formulaBasis: '-0.01',

      contractStartDate: false,
      contractEndDate: false,
      voyage: Constants.INITIAL_VOYAGE,
      buyIncoterms: '',
      productType: false,
      purchasingOffice: false,
      endUser: null,
    };

    this.setState({
      grinder: null,
      packerPlant: null,
      packer: null,
      formType: false,
      baseForm: cleanBase,
    });
  };

  onChangeValue = (key, e) => {
    // Due to the nested nature of baseForm, every form change triggers an update of the baseForm
    // object.
    // We selectively update other state properties depending on the key being updated
    const { baseForm } = this.state;
    const newState = {
      baseForm: {
        ...baseForm,
      },
    };

    // When we change the purchasing office, we reset key fields
    switch (key) {
      case 'purchasingOffice': {
        const { grinderOptions, packerOptions, packerPlantOptions } = this.state;
        newState.filteredGrinderOptions = filterOptionsByPurchasingOffice(grinderOptions, e.value);
        newState.filteredPackerOptions = filterOptionsByPurchasingOffice(packerOptions, e.value);
        newState.filteredPackerPlantOptions = filterOptionsByPurchasingOffice(packerPlantOptions, e.value);
        newState.baseForm.purchasingOffice = e.value;
        newState.baseForm.estId = '';
        newState.baseForm.packerId = '';
        newState.baseForm.grinderUid = '';
        newState.packer = null;
        newState.grinder = null;
        newState.packerPlant = null;
        newState.validationErrors = {
          requiredFields: { form: [], pos: [] },
        };
        newState.errorMessage = null;
        break;
      }

      case 'estId': {
        const packerPlant = this.state.filteredPackerPlantOptions.find(obj => obj.estId === e);
        const packer = this.state.filteredPackerOptions.find(opt => opt.value === get(packerPlant, 'packerId'));
        newState.baseForm.estId = e;
        // TODO FIXME, remove duplicate fields ex: baseform.packerPlant, state.packerPlant
        if (packerPlant === undefined) {
          // Reset Base form Packer Plant and Packer Fields if the plant can't be found
          newState.baseForm.packerPlantId = '';
          newState.baseForm.packerId = '';
          // packerPlant and packer are stored separately to prevent lookups during render()
          newState.packerPlant = '';
          newState.packer = '';
        } else {
          newState.baseForm.packerId = packerPlant.packerId;
          newState.baseForm.packerPlantId = packerPlant.id;
          newState.baseForm.buyCurrency = packerPlant.defaultBuyCurrency;
          // packerPlant and packer are stored separately to prevent lookups during render()
          newState.packerPlant = packerPlant;
          newState.packer = packer;
        }

        // Analyze form attributes
        newState.formAttributes = analyzeBasePOForm({
          grinder: this.state.grinder,
          packer: newState.packer,
          packerPlant: newState.packerPlant,
        });
        break;
      }

      case 'packerPlant': {
        if (!e) return;

        newState.packerPlant = this.state.filteredPackerPlantOptions.find(opt => opt.value === e.value);

        if (!newState.packerPlant) return;

        newState.baseForm.packerPlantId = newState.packerPlant.value;
        newState.baseForm.estId = newState.packerPlant.estId || '';

        // set or unset shipment/arrival flag on change of packer_plant
        // by default set shipment to true when order's updated to international
        if (this.state.grinder && this.state.grinder.destinationCountry) {
          if (newState.packerPlant.originCountry === this.state.grinder.destinationCountry) {
            newState.baseForm.arrival = false;
            newState.baseForm.shipment = false;
          } else {
            newState.baseForm.shipment = true;
          }
        }
        // auto populate packer if packerPlantId available
        if (newState.baseForm.packerPlantId !== null) {
          newState.baseForm.packerId = newState.packerPlant.packerId;
          const packer = this.state.filteredPackerOptions.find(opt => opt.value === newState.packerPlant.packerId);
          newState.packer = packer;
        }
        // Analyze form attributes
        newState.formAttributes = analyzeBasePOForm({
          grinder: this.state.grinder,
          packer: newState.packer,
          packerPlant: newState.packerPlant,
        });
        newState.errorMessage = null;
        break;
      }

      case 'grinder': {
        if (e) {
          newState.grinder = this.state.filteredGrinderOptions.find(opt => opt.value === e.value);
          newState.baseForm.grinderUid = e.value;
        } else {
          newState.grinder = null;
          newState.endUserOptionsFilteredByGrinder = null;
          newState.grinderOptionsFIlteredByEndUser = null;
        }
        // set or unset shipment/arrival flag on change of grinder
        // by default set shipment to true when order's updated to international
        if (newState.packerPlant && newState.packerPlant.originCountry) {
          if (newState.packerPlant.originCountry === newState.grinder.destinationCountry) {
            newState.baseForm.arrival = false;
            newState.baseForm.shipment = false;
          } else {
            newState.baseForm.shipment = true;
          }
        }

        if (newState.grinder && newState.grinder.defaultPortId) {
          if (baseForm.voyage && baseForm.voyage.dischargePortId !== newState.grinder.defaultPortId)
            newState.baseForm.voyage.dischargePortId = newState.grinder.defaultPortId;
        }

        // Analyze form attributes
        newState.formAttributes = analyzeBasePOForm({
          grinder: newState.grinder,
          packer: this.state.packer,
          packerPlant: this.state.packerPlant,
        });
        newState.endUserOptionsFilteredByGrinder = filterEndUserOptionsByGrinder(newState.grinder, this.props.endUsers);
        break;
      }

      case 'packer': {
        // reset values when packer changes
        const packer = this.state.filteredPackerOptions.find(opt => opt.value === e.value);

        // Filter Packer Plants base on packer selection.
        // Also reset existing packerPlant and estId
        if (baseForm.packerId !== e.value) {
          newState.baseForm.estId = '';
          newState.baseForm.packerPlantId = '';
          newState.packerPlant = null;
          newState.packer = packer;
          const purchasingOffice = baseForm?.purchasingOffice || Constants.BUSINESS_OFFICES.SYDNEY.value;
          const filtered = filterOptionsByPurchasingOffice(this.state.packerPlantOptions, purchasingOffice);
          newState.filteredPackerPlantOptions = filterPackerPlantsByPackerId(filtered, e.value);
          const filterByPacker = filterPackerPlantsByPackerId(this.state.packerPlantOptions, e.value);
          const filterByPurchasingOffice = filterOptionsByPurchasingOffice(filterByPacker, purchasingOffice);
          if ((filterByPacker.length !== 0 && filterByPurchasingOffice.length === 0) || filterByPacker.length === 0) {
            newState.errorMessage = 'No establishment has purchasing_office configured';
            newState.filteredPackerPlantOptions = filtered;
          } else {
            newState.errorMessage = null;
          }
        }
        // update new value in form
        newState.baseForm.packerId = packer.id;
        // Update form attributes
        newState.formAttributes = analyzeBasePOForm({
          packer: newState.packer,
          packerPlant: this.state.packerPlant,
          grinder: this.state.grinder,
        });
        break;
      }

      case 'singleIngredientForm': {
        newState.baseForm.singleIngredientForm = e;
        newState.baseForm.priceType = e ? 'spot' : 'contract';
        break;
      }

      case 'shipment': {
        newState.baseForm.arrival = e;
        newState.baseForm.shipment = !e;
        break;
      }

      case 'endUserId': {
        if (e) {
          newState.endUser = e;
          newState.baseForm.endUserId = e.value;
          newState.grinderOptionsFIlteredByEndUser = filterGrinderOptionsByEndUser(
            newState.endUser,
            this.state.filteredGrinderOptions
          );
          newState.grinder = this.state.grinder;
          // reset selected grinder if it does not existing in the selected end user
          if (newState.grinder) {
            const existingGrinderInSelectedEndUser = filterGrinderOptionsByEndUser(newState.endUser, [
              newState.grinder,
            ]);
            newState.grinder =
              existingGrinderInSelectedEndUser.length < 1
                ? newState.grinderOptionsFIlteredByEndUser[0]
                : existingGrinderInSelectedEndUser[0];
          }
        } else {
          newState.endUser = null;
          newState.endUserOptionsFilteredByGrinder = null;
          newState.grinderOptionsFIlteredByEndUser = null;
        }
        break;
      }

      case 'productType': {
        newState.baseForm.productType = e;
        break;
      }

      default: {
        newState.baseForm[key] = e && e.value ? e.value : e;
        break;
      }
    }

    this.setState(newState);
  };

  onVoyageValueChange = (voyage, value, key) => {
    const stateCopy = JSON.parse(JSON.stringify(this.state));
    stateCopy.baseForm.voyage = {
      ...voyage,
      [key]: value,
    };
    this.setState(stateCopy);
  };

  validateRequiredFields = (form, requiredFields, isLabelCheck) => {
    const poUom = this.props.unitsOfMeasure.find(uom => uom.id === form.buyUnitOfMeasureId) || { name: 'kgs' };
    const requiredFieldsErrors = validateRequiredFields(form, requiredFields, poUom.name, this.props.configs);
    if (!isLabelCheck) {
      const stateCopy = JSON.parse(JSON.stringify(this.state));
      stateCopy.validationErrors.requiredFields = requiredFieldsErrors;
      this.setState(stateCopy);
    }

    return requiredFieldsErrors;
  };

  handleBuyIncoTermsChange = buyIncoterms => {
    const stateCopy = JSON.parse(JSON.stringify(this.state));
    stateCopy.baseForm.buyIncoterms = buyIncoterms;
    this.setState(stateCopy);
  };

  render() {
    const { containers, priceTypes, ports, endUsers, configs, productTypes, user, users } = this.props;
    const {
      baseForm,
      filteredPackerOptions,
      filteredGrinderOptions,
      filteredPackerPlantOptions,
      formAttributes,
      packer,
      packerPlant,
      grinder,
      validationErrors,
      endUser,
      grinderOptionsFIlteredByEndUser,
      endUserOptionsFilteredByGrinder,
    } = this.state;
    const { requiredFields } = validationErrors;
    // get options from api
    const officeOptions = getOptionsFromSettingsConfig(this.props.settingsConfig, 'CITIES');
    const productOptions = productTypes.map(el => ({
      value: el.value,
      label: el.value,
    }));

    // Initialize options.
    const priceTypeOptions = priceTypes.map(el => ({
      value: el.value,
      label: capitalizeFirstLetter(el.value),
    }));
    const containerOptions = formatOptionsFromFormFields(containers);
    const buyerOptions = constructBuyerOptions(buyersSelector(users), user);
    // get default fields
    const defaultEndUserName = getDefaultValueFromConfigs(configs, 'end_user_default');
    const defaultPurchasingOfficeName = capitalizeFirstLetter(
      getDefaultValueFromConfigs(configs, 'purchasing_office_default')
    );
    const defaultProductTypeName = getDefaultValueFromConfigs(configs, 'product_type_default').toUpperCase();
    const defaultOrderTypeName = getDefaultValueFromConfigs(configs, 'order_type_default');
    const defaultBuyerId = user.id;

    const defaultBuyer = buyerOptions
      .filter(buyer => buyer.id === defaultBuyerId)
      .map(el => ({ value: el.id, label: `${el.first_name || el.firstName} ${el.last_name || el.lastName}` }));

    const endUserOptions = endUsers
      // eslint-disable-next-line no-shadow
      .filter(endUser => endUser.isActive === true)
      .map(_endUser => ({
        label: _endUser.legalName,
        value: _endUser.id,
        grinders_uids: _endUser.grindersUids,
      }));
    // match default fields
    // eslint-disable-next-line no-shadow
    const defaultEndUser = endUserOptions.filter(endUser => endUser.label === defaultEndUserName);
    if (
      !endUser &&
      defaultEndUser.length === 1 &&
      typeof defaultEndUser[0] === 'object' &&
      !_.isEqual(defaultEndUser[0], endUser)
    ) {
      this.onChangeValue('endUserId', defaultEndUser[0]);
    }

    let productTypeValue = productOptions.find(product => product.value === defaultProductTypeName);
    if (baseForm.productType) {
      productTypeValue = productOptions.filter(product => product.value === baseForm.productType);
    } else {
      baseForm.productType = productTypeValue?.value;
    }

    let purchasingOfficeValue = officeOptions.find(office => office.value === defaultPurchasingOfficeName);
    if (baseForm.purchasingOffice) {
      purchasingOfficeValue = officeOptions.filter(product => product.value === baseForm.purchasingOffice);
    } else {
      baseForm.purchasingOffice = purchasingOfficeValue?.value;
    }

    let priceTypeValue = priceTypeOptions.find(priceType => priceType.value === defaultOrderTypeName);
    if (baseForm.priceType) {
      priceTypeValue = priceTypeOptions.filter(priceType => priceType.value === baseForm.priceType);
    } else {
      baseForm.priceType = priceTypeValue?.value;
    }

    // Determine Port
    // const isPortOfLoadingEnabled = togglePortOfLoadingDisplay(packers, baseForm);

    // Determine selections from state
    const showGrinderPo = grinder?.poStrategy === Constants.PO_STRATEGY.MANUAL;
    const requiredFieldsForm = getOr(requiredFields, 'form', []);
    return (
      <InternationalOrderFormContainer
        {...(hasSelectedPackerAndGrinder({ packer, packerPlant, grinder }) && {
          baseForm,
          clearFormData: this.clearFormData,
          formAttributes,
          onBuyIncoTermsChange: this.handleBuyIncoTermsChange, // used for lifting buy incoterms state up when buy incoterms is changed
          validationErrors,
          ...this.props,
        })}
        validateRequiredFields={this.validateRequiredFields}
      >
        <VStack gap="26px" align="stretch">
          <Text as="p" fontSize="18px" fontWeight="bold">
            Order Details
          </Text>
          <DashboardSectionTile title="Order Details" gap="30px">
            <Flex gap="20px">
              <CustomFormControl>
                <POFormFieldLabel
                  label="Office"
                  tooltip="Specifies the office managing the order for coordination."
                  htmlFor="select-purchasingOffice"
                  fieldName="purchasingOffice"
                  className="create-po-form__label"
                />
                <SelectField
                  name="select-purchasingOffice"
                  value={purchasingOfficeValue}
                  options={officeOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  placeholder="Purchasing Office"
                  className={requiredFields?.form?.includes('purchasingOffice') ? 'error' : ''}
                  onChange={e => this.onChangeValue('purchasingOffice', e)}
                />
              </CustomFormControl>
              <CustomFormControl>
                <POFormFieldLabel
                  label="Order Type"
                  tooltip="Categorizes the order for processing and management."
                  htmlFor="select-priceType"
                  fieldName="priceType"
                  className="create-po-form__label"
                />
                <SelectField
                  name="select-price-type"
                  value={priceTypeValue}
                  options={priceTypeOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  placeholder="Order Type"
                  className={requiredFieldsForm.includes('priceType') ? 'error' : ''}
                  onChange={e => this.onChangeValue('priceType', e)}
                />
              </CustomFormControl>
              <CustomFormControl>
                <POFormFieldLabel
                  label="Product Type"
                  tooltip="Specifies the type of meat product being ordered."
                  htmlFor="select-product-type"
                  fieldName="productType"
                  className="create-po-form__label"
                />
                <SelectField
                  name="select-product-type"
                  value={productTypeValue}
                  options={productOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  placeholder="Product Type"
                  className={requiredFieldsForm.includes('productType') ? 'error' : ''}
                  onChange={e => this.onChangeValue('productType', e.value)}
                />
              </CustomFormControl>
            </Flex>
            {/* <!-- CONTRACT SECTION --> */}
            {baseForm.priceType === 'contract' ? (
              <Flex gap="20px">
                <ContractSection form={baseForm} requiredFields={requiredFields} onChangeValue={this.onChangeValue} />
              </Flex>
            ) : (
              false
            )}
            {/* <!-- FORMULA SECTION --> */}
            {baseForm.priceType === 'formula' ? (
              <Flex gap="20px">
                <FormulaSection form={baseForm} onValueChange={this.onChangeValue} requiredFields={requiredFields} />
              </Flex>
            ) : (
              false
            )}
            <Flex spacing="29px" gap="20px">
              <CustomFormControl>
                <POFormFieldLabel
                  label="Establishment Number"
                  tooltip="Records the official number for traceability and compliance."
                  htmlFor="estId"
                  fieldName="estId"
                  className="create-po-form__label"
                />
                <Input
                  bgColor="white"
                  name="estId"
                  value={baseForm.estId}
                  placeholder="123"
                  className={requiredFieldsForm.includes('estId') ? 'form-control error' : 'form-control'}
                  onChange={e => this.onChangeValue('estId', e.target.value)}
                />
              </CustomFormControl>
              <CustomFormControl>
                <POFormFieldLabel
                  label="Establishment"
                  tooltip=" Identifies the establishment associated with the order."
                  htmlFor="select-est"
                  fieldName="packerPlantId"
                  className="create-po-form__label"
                />
                <SelectField
                  name="select-est"
                  value={packerPlant || null}
                  options={filteredPackerPlantOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  placeholder="Establishment"
                  className={requiredFieldsForm.includes('packerPlantId') ? 'error' : ''}
                  onChange={e => this.onChangeValue('packerPlant', e)}
                />
                {this.state.errorMessage && <Alert status="warning" mb="15px" title={this.state.errorMessage} />}
              </CustomFormControl>
              <CustomFormControl>
                <POFormFieldLabel
                  label="Packer"
                  tooltip="Specifies the packer responsible for handling and tracking the product."
                  htmlFor="select-packer"
                  fieldName="packerId"
                  className="create-po-form__label"
                />
                <SelectField
                  name="select-packer"
                  value={packer || null}
                  options={filteredPackerOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  placeholder="Packer"
                  className={requiredFieldsForm.includes('packerId') ? 'error' : ''}
                  onChange={e => this.onChangeValue('packer', e)}
                />
                {filteredPackerOptions.length <= 0 && (
                  <Alert status="warning" mb="15px" title="No packer has purchasing_office configured" />
                )}
              </CustomFormControl>
            </Flex>
            <Flex spacing="29px" gap="20px">
              <CustomFormControl>
                <POFormFieldLabel
                  label="End User"
                  tooltip="Identifies the final recipient to ensure the product meets specific requirements."
                  htmlFor="select-end_user"
                  fieldName="endUserId"
                  className="create-po-form__label"
                />
                <SelectField
                  name="select-end_user"
                  options={
                    endUserOptionsFilteredByGrinder?.sort((a, b) => (a.label > b.label ? 1 : -1)) ||
                    endUserOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))
                  }
                  placeholder="End User"
                  value={endUser}
                  className={requiredFieldsForm.includes('endUserId') ? 'error' : ''}
                  onChange={e => this.onChangeValue('endUserId', e)}
                  isClearable
                />
              </CustomFormControl>
              <CustomFormControl>
                <POFormFieldLabel
                  label="Grinder"
                  tooltip="Specifies the grinder used for correct equipment and processes."
                  htmlFor="select-grinder"
                  fieldName="grinderUid"
                  className="create-po-form__label"
                />
                <SelectField
                  name="select-grinder"
                  options={
                    grinderOptionsFIlteredByEndUser?.sort((a, b) => (a.label > b.label ? 1 : -1)) ||
                    filteredGrinderOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))
                  }
                  placeholder="Grinder"
                  value={grinder || null}
                  className={requiredFieldsForm.includes('grinderUid') ? 'error' : ''}
                  onChange={e => this.onChangeValue('grinder', e)}
                  isClearable
                />
                {filteredGrinderOptions.length <= 0 && (
                  <Alert status="warning" mb="15px" title="No grinder has purchasing_office configured" />
                )}
              </CustomFormControl>
              {showGrinderPo && (
                <CustomFormControl>
                  <POFormFieldLabel
                    label="Grinder PO#"
                    htmlFor="grinderPoNumber"
                    fieldName="grinderPoNumber"
                    className="create-po-form__label"
                  />
                  <Input
                    name="grinderPoNumber"
                    value={baseForm.grinderPoNumber}
                    placeholder="#00000"
                    className={requiredFieldsForm.includes('grinderPoNumber') ? 'form-control error' : 'form-control'}
                    onChange={e => this.onChangeValue('grinderPoNumber', e.target.value)}
                  />
                </CustomFormControl>
              )}
            </Flex>
            <Flex spacing="29px" gap="20px">
              <CustomFormControl>
                <POFormFieldLabel
                  label="Buyer"
                  tooltip="Identifies the individual or organization purchasing the product."
                  htmlFor="select-buyer"
                  className="create-po-form__label"
                />
                <SelectField
                  name="select-buyer"
                  defaultValue={baseForm.buyerId || defaultBuyer}
                  options={buyerOptions}
                  placeholder="Salesman"
                  onChange={e => this.onChangeValue('buyerId', e)}
                  isClearable={false}
                />
              </CustomFormControl>
              <CustomFormControl>
                <POFormFieldLabel
                  label="Port of Discharge"
                  tooltip=" Specifies where the goods will be unloaded for logistics and planning."
                  htmlFor="grinder-voyage"
                  className="create-po-form__label"
                />
                <PortOptions
                  disabled={false}
                  fieldType="DISCHARGE"
                  voyage={baseForm.voyage}
                  ports={ports?.sort((a, b) => (a.name > b.name ? 1 : -1))}
                  placeholder="Port of Discharge"
                  onVoyageValueChange={this.onVoyageValueChange}
                />
              </CustomFormControl>
              <CustomFormControl>
                <POFormFieldLabel
                  label="Port of Loading"
                  tooltip="Identifies where the goods will be loaded for coordinating shipment details."
                  htmlFor="grinder-port"
                  className="create-po-form__label"
                />
                <PortOptions
                  disabled={false}
                  fieldType="LOAD"
                  voyage={baseForm.voyage}
                  ports={ports?.sort((a, b) => (a.name > b.name ? 1 : -1))}
                  placeholder="Port of Loading"
                  onVoyageValueChange={this.onVoyageValueChange}
                />
              </CustomFormControl>
            </Flex>
            <Flex spacing="29px" gap="20px">
              <CustomFormControl>
                <POFormFieldLabel
                  label="Container/Load Combo"
                  tooltip="Specifies the type of container or load used for shipping."
                  htmlFor="loadContainerDetails"
                  fieldName="loadContainerDetails"
                  className="create-po-form__label"
                />
                <SelectField
                  name="loadContainerDetails"
                  defaultValue={baseForm?.loadContainerDetails}
                  options={containerOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  placeholder="Container/Load Combo"
                  className={requiredFieldsForm.includes('loadContainerDetails') ? 'error' : ''}
                  onChange={e => this.onChangeValue('loadContainerDetails', e)}
                />
              </CustomFormControl>
            </Flex>
            {/* <!-- SINGLE / RECURRING RADIO --> */}
            {/* <Col className="create-po-form__col--field" sm={12}>
                <Box style={{ float: 'right', fontSize: '15px' }}>
                  <input
                    type="radio"
                    name="order-form-type"
                    onChange={() => this.onChangeValue('single_ingredient_form', true)}
                    checked={baseForm.single_ingredient_form}
                    style={radioButtonStyle}
                  />{' '}
                  Single &nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="order-form-type"
                    onChange={() => this.onChangeValue('single_ingredient_form', false)}
                    checked={!baseForm.single_ingredient_form}
                    style={radioButtonStyle}
                  />{' '}
                  Recurring
                </Box>
              </Col> */}
            {/* <!-- INTERNATIONAL ORDER DETAILS --> */}
            {hasSelectedPackerAndGrinder({ packer, packerPlant, grinder }) && (
              <InternationalOrderDetails editMode showToggles />
            )}
          </DashboardSectionTile>
          {hasSelectedPackerAndGrinder({ packer, packerPlant, grinder }) && (
            <DashboardSectionTile title="Order Details">
              <InternationalOrderForm />
            </DashboardSectionTile>
          )}
          {hasSelectedPackerAndGrinder({ packer, packerPlant, grinder }) && (
            <DashboardSectionTile title="Comments / Notes">
              {/* <!-- COMMENTS --> */}
              <Comments />
            </DashboardSectionTile>
          )}
          {hasSelectedPackerAndGrinder({ packer, packerPlant, grinder }) && <InternationalOrderFormEndSection />}
        </VStack>
      </InternationalOrderFormContainer>
    );
  }
}

const CreatePurchaseOrderFormUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.SYDNEY_ORDER}
        show={<CreatePurchaseOrderForm {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default CreatePurchaseOrderFormUnleashController;
