import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Flex } from '@chakra-ui/react';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';
import AuthGate from '../../containers/auth/AuthGate';
import Constants from '../../Constants';
import { Switch } from '@/components/ui/switch';

const OrderManagementTabList = ({ handleNavigation, tabs, poGridtoggleStatus, handleToggleChange }) => {
  return (
    <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
      <Tabs.List borderBottomWidth="0px" textAlign="center">
        {tabs.map(tab => (
          <AuthGate
            key={tab.name}
            requiredPermissions={
              Constants.ORDER_MANAGEMENT_PERMISSIONS[tab.name]
                ? [Constants.ORDER_MANAGEMENT_PERMISSIONS[tab.name]]
                : undefined
            }
          >
            <Tabs.Trigger
              value={tab.name}
              key={tab.name}
              _focus={{ outline: 'none' }}
              _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
              _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
              fontWeight="normal"
              width="162px"
              fontSize="14px"
              padding="0px"
              height="103px"
              marginRight="10px"
              justifyContent="center"
              onClick={() => handleNavigation(tab.type, tab.name)}
            >
              {tab.label}
            </Tabs.Trigger>
          </AuthGate>
        ))}
      </Tabs.List>

      <Flex alignItems="right" justifyContent="flex-end" padding="0px" marginLeft="auto">
        <span style={{ marginRight: '8px' }}>PO Grid</span>
        <Switch checked={poGridtoggleStatus} onChange={handleToggleChange} colorScheme="teal" />
      </Flex>
    </HorizontalNavigationBand>
  );
};

OrderManagementTabList.propTypes = {
  handleNavigation: PropTypes.func,
  poGridtoggleStatus: PropTypes.bool,
  handleToggleChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.objectOf({
      type: PropTypes.string,

      name: PropTypes.string,

      label: PropTypes.string,
    })
  ),
};

export default OrderManagementTabList;
