import { Box, Button, Heading, Grid } from '@chakra-ui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { errorSendEmail, sendEmail } from '../../../../actions/actions_email';
import { resetConfDoc } from '../../../../actions/actions_orders';
// Local Deps
import Constants from '../../../../Constants';
import { toFixed2 } from '../../../../functions';
import { getPurchaseOrderIds } from '../../../lib/PurchaseOrderFunctions';
import ConfirmationButtons from '../../ConfirmationButtons/ConfirmationButtons';
import DocumentEmailModal from '../../DocumentEmailModal';
import ConfirmationDetails from '../ConfirmationDetails/ConfirmationDetails';
import {
  constructOrderLines,
  constructRecurringOrderLinesFromDeliveryDates,
  createConfirmationDateString,
  generateRecurringDeliveryDates,
} from './helpers';
import './InternationalConfirmation.scss';
import { getInternalPoLabel } from '../../../../utils';
import {
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '@/components/ui/dialog';

class InternationalConfirmation extends Component {
  static propTypes = {
    order: PropTypes.shape({
      buyCurrency: PropTypes.string,
      buyerId: PropTypes.number,
      buyIncoterms: PropTypes.string.isRequired,
      buyUnitOfMeasureId: PropTypes.number,
      foreignExchangeRate: PropTypes.string.isRequired,
      grinderUid: PropTypes.string,
      packerId: PropTypes.string,
      packerPlantId: PropTypes.string,
      purchaseOrders: PropTypes.instanceOf(Object),
      sellCurrency: PropTypes.string,
      sellIncoterms: PropTypes.string.isRequired,
      sellUnitOfMeasureId: PropTypes.number,
    }).isRequired,
    closeModal: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    email: PropTypes.shape({
      isSendingEmail: PropTypes.bool,
      sendEmailError: PropTypes.string,
    }),
    inputProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    grinders: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string,
      })
    ),
    packerPlants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    packers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    fastLoaded: PropTypes.any,
    latestOrders: PropTypes.arrayOf(PropTypes.object),
    poDocuments: PropTypes.shape({
      cos: PropTypes.instanceOf(Object).isRequired,
      cop: PropTypes.instanceOf(Object).isRequired,
    }).isRequired,
    token: PropTypes.string.isRequired,
    unitsOfMeasure: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    user: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    configs: PropTypes.arrayOf(PropTypes.object),
    apiRequestError: PropTypes.string,
  };

  state = {
    emailModalIsOpen: false,
    purchaseOrderIds: [],
    loading: false,
  };

  componentDidUpdate(prevProps, prevState) {
    // Close email modal once it successfuly sends
    const { email, apiRequestError } = this.props;
    const { emailModalIsOpen } = this.state;
    if (prevProps?.email?.isSendingEmail && !email?.isSendingEmail && !email?.sendEmailError && emailModalIsOpen) {
      this.setState({
        emailModalIsOpen: false,
      });
    }

    // reset loading state when request fails
    if (apiRequestError !== prevProps.apiRequestError) {
      this.setState({
        loading: false,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideModalClick);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetConfDoc());
    document.removeEventListener('mousedown', this.handleOutsideModalClick);
  }

  handleOutsideModalClick = e => {
    if (!this.node) {
      return;
    }

    if (this.node.contains(e.target)) {
      return;
    }
    this.props.closeModal(this.state.fastLoaded);
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.fastLoaded) {
      const purchaseOrderIds = getPurchaseOrderIds(nextProps.latestOrders);
      this.setState({
        loading: false,
        fastLoaded: nextProps.fastLoaded,
        latestOrders: nextProps.latestOrders,
        purchaseOrderIds,
      });
    }
  }

  setEmailProperties = documentType => {
    const { emailModalIsOpen } = this.state;
    this.setState({
      documentType,
      emailModalIsOpen: !emailModalIsOpen,
    });
  };

  handleConfirm = () => {
    this.setState({ loading: true });
    this.props.save(this.props.order, true);
  };

  handleEmaileModalCancel = () => {
    this.setState({
      emailModalIsOpen: false,
    });
  };

  handleSendEmail = email => {
    const { dispatch, token } = this.props;

    if (!email || !email.subject || isEmpty(email.recipients)) {
      dispatch(
        errorSendEmail(
          'This email is missing either a subject or some recipients.  Please check these fields and try again.'
        )
      );
    }

    dispatch(sendEmail(token, email));
  };

  render() {
    const {
      closeModal,
      order,
      packerPlants,
      packers,
      grinders,
      unitsOfMeasure,
      inputProducts,
      users,
      user,
      email,
      poDocuments,
      token,
      dispatch,
      configs,
    } = this.props;
    const { latestOrders, emailModalIsOpen, fastLoaded, documentType } = this.state;
    const packerPlant = packerPlants.find(p => p.id === order.packerPlantId);
    const grinder = grinders.find(g => g.uid === order.grinderUid);
    const buyUom = unitsOfMeasure.find(u => u.id === order.buyUnitOfMeasureId);
    const sellUom = unitsOfMeasure.find(u => u.id === order.sellUnitOfMeasureId);
    const buyer = users.find(u => u.id === order.buyerId);

    const poIds = latestOrders ? latestOrders.map(o => o.id) : [];
    const fmgPONumbers = latestOrders ? latestOrders.map(o => o.internalPoNumber) : [];
    const grinderPONumbers = latestOrders ? latestOrders.map(o => o.grinderPoNumber) : [];
    // there's no order.purchaseOrders available. But this is un-used UI feature
    const purchaseOrders = latestOrders || Object.values(order.purchaseOrders);

    const augmentedLines = purchaseOrders.reduce((agg, po) => {
      const lines = Object.values(po.lines);
      const recurringDeliveryDates = generateRecurringDeliveryDates(po);

      const orderLines = lines.map(line => {
        return constructOrderLines(line, po, buyUom, sellUom, inputProducts, order);
      });

      const recurringOrderLinesWithDates = recurringDeliveryDates.reduce(
        (agg2, item) => constructRecurringOrderLinesFromDeliveryDates(agg2, item, orderLines),
        []
      );

      // this assumes that users can either do recurring order entries or normal order entries (but not both) on a single order enrry form
      const otherOrderLinesWithDates =
        recurringOrderLinesWithDates.length === 0
          ? orderLines.map(line => ({
              ...line,
              dateString: createConfirmationDateString(po.dateType, {
                shipmentDateStart: po.shipmentDateStart,
                shipmentDateEnd: po.shipmentDateEnd,
                arrivalDateStart: po.arrivalDateStart,
                arrivalDateEnd: po.arrivalDateEnd,
                deliveryDate: po.deliveryDate,
              }),
            }))
          : [];

      // This is my version of a for loop where n = po.copies
      const newArray = Array(po.copies || 1).fill(1);
      const copiedLines = newArray.reduce(
        agg3 => [...agg3, ...otherOrderLinesWithDates, ...recurringOrderLinesWithDates],
        []
      );

      return [...agg, ...copiedLines];
    }, []);

    const grandBuySum = toFixed2(sumBy(augmentedLines, line => parseFloat(line.buyPrice)));
    const grandSellSum = toFixed2(sumBy(augmentedLines, line => parseFloat(line.sellPrice)));

    const grandBuyQuantity = sumBy(augmentedLines, line => parseFloat(line.buyQuantity));
    const grandSellQuantity = sumBy(augmentedLines, line => parseFloat(line.sellQuantity));

    const totalLine = {
      totals: 'Totals',
      buyPricePerUnit: grandBuySum,
      sellPricePerUnit: grandSellSum,
      buyQuantity: grandBuyQuantity,
      sellQuantity: grandSellQuantity,
      buyCurrency: order.buyCurrency,
      sellCurrency: order.sellCurrency,
      buyUom,
      sellUom,
    };

    const sortedLines = augmentedLines.sort((a, b) => {
      const dateStartA = a?.dateString.split('to')[0];
      const dateStartB = b?.dateString.split('to')[0];
      return dateStartA.localeCompare(dateStartB);
    });

    const allLines = [...sortedLines, totalLine];

    const docType = documentType === 'Purchase' ? 'cop' : 'cos';
    const confirmationDocument = get(poDocuments, docType);
    const isMissingConfirmationDocument = !confirmationDocument || !confirmationDocument.url;
    const internalPoLabel = getInternalPoLabel(configs);
    return (
      <Box className="international-confirmation">
        {emailModalIsOpen && (
          <DocumentEmailModal
            attachmentList={confirmationDocument ? [{ ...confirmationDocument, docType: docType.toUpperCase() }] : []}
            documentType={docType.toUpperCase()}
            error={email.sendEmailError}
            fmgPONumbers={fmgPONumbers}
            grinderPONumbers={grinderPONumbers}
            isLoadingAttachments={isMissingConfirmationDocument}
            isSendingEmail={email.isSendingEmail}
            isVisible={emailModalIsOpen}
            onCancel={this.handleEmaileModalCancel}
            onSend={this.handleSendEmail}
            purchaseOrderIds={poIds}
            recipients={[user.email]}
            recipientsGroup={
              docType === 'cop'
                ? ['confirmation_of_purchase', 'internal_users']
                : ['confirmation_of_sale', 'internal_users']
            }
            order={order}
            internalPoLabel={internalPoLabel}
          />
        )}
        {!emailModalIsOpen && (
          <Box style={{ display: 'block' }}>
            <DialogRoot open size="cover">
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>
                    <Heading as="h1" className="international-confirmation__header">
                      Confirmation
                    </Heading>
                  </DialogTitle>
                </DialogHeader>
                <DialogBody overflowY="auto">
                  <ConfirmationDetails
                    order={order}
                    loading={this.state.loading}
                    grinder={grinder}
                    packerPlant={packerPlant}
                    buyer={buyer}
                    lines={allLines}
                    internalPoLabel={internalPoLabel}
                  />
                  {isEmpty(this.state.purchaseOrderIds) ? (
                    <Box
                      className="international-confirmation__footer"
                      style={{ justifyContent: 'baseline' }}
                      gap="20px"
                      display="flex"
                    >
                      <DialogActionTrigger asChild>
                        <Button
                          color="#333"
                          className="international-confirmation__button--link"
                          data-dismiss="modal"
                          onClick={() => closeModal(false)}
                          backgroundColor="gray.100"
                        >
                          <i className="la la-trash-o" /> Cancel
                        </Button>
                      </DialogActionTrigger>

                      <Button
                        colorScheme="actionPrimary"
                        data-dismiss="modal"
                        onClick={this.handleConfirm}
                        disabled={this.state.loading}
                      >
                        Confirm
                      </Button>
                    </Box>
                  ) : (
                    <Grid templateColumns="repeat(2, 1fr)" gap="20px">
                      <ConfirmationButtons
                        documentType={Constants.PO_DOC_TYPES.PURCHASE}
                        poIds={poIds}
                        setEmailProperties={this.setEmailProperties}
                        token={token}
                        dispatch={dispatch}
                        confirmationDocument={poDocuments.cop}
                      />
                      <ConfirmationButtons
                        documentType={Constants.PO_DOC_TYPES.SALE}
                        poIds={poIds}
                        setEmailProperties={this.setEmailProperties}
                        token={token}
                        dispatch={dispatch}
                        confirmationDocument={poDocuments.cos}
                      />
                    </Grid>
                  )}
                </DialogBody>
                {/* <DialogFooter></DialogFooter> */}
                <DialogCloseTrigger
                  onClick={() => {
                    closeModal(fastLoaded);
                  }}
                />
              </DialogContent>
            </DialogRoot>

            {/* <Box
              className="modal fade show"
              id="m_modal_4"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              style={{ overflowY: 'scroll', display: 'block' }}
            >
              <Box className="modal-dialog modal-md" role="document">
                <Box
                  className="modal-content"
                  ref={node => {
                    this.node = node;
                  }}
                >
                  <Box className="modal-header">
                    <Heading as="h1" className="international-confirmation__header">
                      Confirmation
                    </Heading>
                    <Button
                      className="international-confirmation__button--close close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        closeModal(fastLoaded);
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </Button>
                  </Box>
                  <Box className="modal-body">
                    <ConfirmationDetails
                      order={order}
                      loading={this.state.loading}
                      grinder={grinder}
                      packerPlant={packerPlant}
                      buyer={buyer}
                      lines={allLines}
                      internalPoLabel={internalPoLabel}
                    />
                    <Box className="modal-footer" style={{ justifyContent: 'baseline' }}>
                      {isEmpty(this.state.purchaseOrderIds) ? (
                        <Box className="international-confirmation__footer">
                          <Button
                            color="link"
                            className="international-confirmation__button--link"
                            data-dismiss="modal"
                            onClick={() => closeModal(false)}
                          >
                            <i className="la la-trash-o" /> Cancel
                          </Button>
                          <Button
                            colorScheme="actionPrimary"
                            data-dismiss="modal"
                            onClick={this.handleConfirm}
                            disabled={this.state.loading}
                          >
                            Confirm
                          </Button>
                        </Box>
                      ) : (
                        <Box className="col-md-12 row">
                          <ConfirmationButtons
                            documentType={Constants.PO_DOC_TYPES.PURCHASE}
                            poIds={poIds}
                            setEmailProperties={this.setEmailProperties}
                            token={token}
                            dispatch={dispatch}
                            confirmationDocument={poDocuments.cop}
                          />
                          <ConfirmationButtons
                            documentType={Constants.PO_DOC_TYPES.SALE}
                            poIds={poIds}
                            setEmailProperties={this.setEmailProperties}
                            token={token}
                            dispatch={dispatch}
                            confirmationDocument={poDocuments.cos}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="modal-backdrop fade show" /> */}
          </Box>
        )}
      </Box>
    );
  }
}

export default InternationalConfirmation;
