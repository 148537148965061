import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Box } from '@chakra-ui/react';
import accounting from 'accounting';
import './ConfirmationPurchaseOrdersGrid.scss';
import Constants from '../../../../Constants';
import { ClickToCopy } from '../../../gridHelpers';
import { formatMonetaryValue } from '../../../../functions';
import { random } from 'lodash';

const ROW_HEIGHT = 60;

class ConfirmationPurchaseOrdersGrid extends Component {
  static propTypes = {
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        buyQuantity: PropTypes.number,
        buyPricePerUnit: PropTypes.number,
        buyUom: PropTypes.shape({
          name: PropTypes.string,
        }),
        buyCurrency: PropTypes.string,
        sellQuantity: PropTypes.number,
        sellPricePerUnit: PropTypes.number,
        sellUom: PropTypes.shape({
          name: PropTypes.string,
        }),
        sellCurrency: PropTypes.string,
        totals: PropTypes.string,
        internalPoNumber: PropTypes.string,
        grinderPoNumber: PropTypes.string,
        dateString: PropTypes.string,
        inputProduct: PropTypes.shape({
          name: PropTypes.string,
        }),
        specifications: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    internalPoLabel: PropTypes.string,
    grinder: PropTypes.shape({
      poStrategy: PropTypes.string,
    }),
  };

  static getBuyData(line) {
    if (!line) return [''];
    const { buyQuantity, buyPricePerUnit, buyUom, buyCurrency } = line;
    return [
      <Box>{`${accounting.formatNumber(buyQuantity, 2, '')} ${buyUom && buyUom.name}`}</Box>,
      <Box>
        {formatMonetaryValue(buyCurrency, buyPricePerUnit, {
          notation: 'standard',
          maximumFractionDigits: 4,
          minimumFractionDigits: 2,
        })}
      </Box>,
    ];
  }

  static getSellData(line) {
    const { sellQuantity, sellPricePerUnit, sellUom, sellCurrency } = line;
    return [
      <Box>{`${accounting.formatNumber(sellQuantity, 2, '')} ${sellUom && sellUom.name}`}</Box>,
      <Box>
        {formatMonetaryValue(sellCurrency, sellPricePerUnit, {
          notation: 'standard',
          maximumFractionDigits: 4,
          minimumFractionDigits: 2,
        })}
      </Box>,
    ];
  }

  static formatBuySellData(dataList) {
    return (
      <Box className="confirmation-purchase-orders-grid__buy-sell-data">
        {dataList.map((datum, idx) => (
          <span key={`cell-data--${random()}`} className={`confirmation-purchase-orders-grid__cell-data-${idx}`}>
            {datum}
          </span>
        ))}
      </Box>
    );
  }

  static formatIngredient({ value }) {
    return value.name;
  }

  static formatFooter({ renderBaseRow, ...props }) {
    // if footer then add styles
    const footerStyle = props.idx + 1 === props.rowVisibleEndIdx ? 'confirmation-purchase-orders-grid__footer' : '';
    return <Box className={footerStyle}>{renderBaseRow(props)}</Box>;
  }

  /*---------------------------------------
    COLUMNS CONFIGURATION
  ----------------------------------------*/
  columns = [
    {
      key: 'totals',
      width: 100,
      formatter: ({ row }) => (
        <Box className="confirmation-purchase-orders-grid__cell-data--centered">{get(row, 'totals')}</Box>
      ),
    },
    {
      key: 'internalPoNumber',
      width: 150,
      name: this.props.internalPoLabel,
      formatter: ({ row }) => (
        <ClickToCopy
          style={{ backgroundColor: 'transparent' }}
          className="confirmation-purchase-orders-grid__po"
          valueToCopy={get(row, 'internalPoNumber')}
        >
          {get(row, 'internalPoNumber')}
        </ClickToCopy>
      ),
    },
    {
      key: 'grinderPoNumber',
      width: 150,
      name: 'Grinder PO #',
      formatter: ({ row }) => (
        <ClickToCopy
          style={{ backgroundColor: 'transparent' }}
          className="confirmation-purchase-orders-grid__po"
          valueToCopy={get(row, 'grinderPoNumber')}
        >
          {get(row, 'grinderPoNumber')}
        </ClickToCopy>
      ),
    },
    {
      key: 'dateString',
      name: 'Date(s)',
      width: 230,
    },
    {
      key: 'inputProduct',
      name: 'Product',
      formatter: ({ row }) => <Box>{get(row, 'inputProduct.name')}</Box>,
    },
    {
      key: 'specifications',
      name: 'Specifications',
      formatter: ({ row }) => {
        const specifications = get(row, 'specifications') || [];
        return <Box>{Array.from(specifications, v => v).join(', ')}</Box>;
      },
    },
    {
      key: 'buyPrice',
      name: 'Buy',
      formatter: ({ row }) => {
        const buyData = ConfirmationPurchaseOrdersGrid.getBuyData(row);
        return ConfirmationPurchaseOrdersGrid.formatBuySellData(buyData);
      },
    },
    // {
    //   key: 'sellPrice',
    //   name: 'Sell',
    //   getRowMetaData: ConfirmationPurchaseOrdersGrid.getSellData,
    //   formatter: ConfirmationPurchaseOrdersGrid.formatBuySellData,
    // },
  ];

  render() {
    const { rows, grinder } = this.props;
    const count = rows.length || 1;
    const firstRow = get(rows, '[0]', {});
    let columns = firstRow.grinderPoNumber ? this.columns : this.columns.filter(col => col.key !== 'grinderPoNumber');

    if (grinder.poStrategy === Constants.PO_STRATEGY.MANUAL) {
      columns = columns.filter(col => col.key !== 'grinderPoNumber');
    }

    const height = 30 + count * ROW_HEIGHT + 1;
    return (
      <Box className="confirmation-purchase-orders-grid">
        <ReactDataGrid
          className="rdg-light"
          columns={columns}
          rows={rows}
          rowHeight={ROW_HEIGHT}
          headerRowHeight={30}
          style={{ height }}
          columnDefaultOptions={{
            minWidth: 40,
          }}
          // rowRenderer={ConfirmationPurchaseOrdersGrid.formatFooter}
        />
      </Box>
    );
  }
}

export default ConfirmationPurchaseOrdersGrid;
