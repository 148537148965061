import { Box, IconButton, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import InternationalOrderLine from '../../../containers/orders/InternationalOrderLine';
import { getIngredientOptions } from '../../../utils';
import ErrorMessage from './ErrorMessage';
import { sortInAlphabeticalOrder } from '../../../functions';
import { findEntitiesInState } from '../../../slices/masterData/entityManagerSlice';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { checkIfChicagoForm } from '../../lib/PurchaseOrderFunctions';
import { Alert } from '../../ui/alert';
import { MdAdd } from 'react-icons/md';

class InternationalOrderLines extends PureComponent {
  static propTypes = {
    buyCurrency: PropTypes.string,
    buyUnitOfMeasureId: PropTypes.number,
    form: PropTypes.instanceOf(Object),
    formType: PropTypes.string,
    grinderDestinationCountry: PropTypes.string.isRequired,
    inputProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    isChilled: PropTypes.bool.isRequired,
    isInEditMode: PropTypes.bool.isRequired,
    hasExpectedProductionDate: PropTypes.bool,
    lineIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
    lines: PropTypes.instanceOf(Object).isRequired,
    onAddLine: PropTypes.func.isRequired,
    onPPUChangeValue: PropTypes.func.isRequired,
    onPPURoundingDecimal: PropTypes.func.isRequired,
    onRemoveLine: PropTypes.func.isRequired,
    onUpdateLine: PropTypes.func.isRequired,
    packerId: PropTypes.string,
    packerPlantOriginCountry: PropTypes.string.isRequired,
    packerPlantId: PropTypes.string.isRequired,
    purchasingOffice: PropTypes.string,
    purchaseOrderId: PropTypes.number,
    purchaseOrderIndex: PropTypes.number.isRequired,
    requiredFields: PropTypes.instanceOf(Object).isRequired,
    requiredFieldsLabels: PropTypes.instanceOf(Object).isRequired,
    sellCurrency: PropTypes.string,
    sellUnitOfMeasureId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shipmentDateEnd: PropTypes.string,
    submitProductionDetails: PropTypes.func,
    submitCLResults: PropTypes.func,
    submitPriceAdjustments: PropTypes.func,
    submitTemperatures: PropTypes.func,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    packageWeights: PropTypes.arrayOf(PropTypes.Object),
    isUSADomestic: PropTypes.bool,
    isLocked: PropTypes.bool,
  };

  state = {
    validationErrors: {
      buyQuantity: false,
      dateError: null,
    },
  };

  setErrorState = (fieldName, errMsgOrStatus) => {
    const { validationErrors } = this.state;
    const validationErrorsCopy = cloneDeep(validationErrors);
    validationErrorsCopy[fieldName] = errMsgOrStatus;
    this.setState({ validationErrors: validationErrorsCopy });
  };

  render() {
    const { validationErrors } = this.state;
    const {
      buyCurrency,
      buyUnitOfMeasureId,
      form,
      formType,
      grinderDestinationCountry,
      hasExpectedProductionDate,
      inputProducts,
      isChilled,
      isInEditMode,
      lineIndices,
      lines,
      onAddLine,
      onPPUChangeValue,
      onPPURoundingDecimal,
      onRemoveLine,
      onUpdateLine,
      packerId,
      packerPlantOriginCountry,
      packerPlantId,
      purchasingOffice,
      purchaseOrderId,
      purchaseOrderIndex,
      requiredFields,
      sellCurrency,
      sellUnitOfMeasureId,
      shipmentDateEnd,
      submitProductionDetails,
      submitCLResults,
      submitTemperatures,
      submitPriceAdjustments,
      packageWeights,
      requiredFieldsLabels,
      isUSADomestic,
      isLocked,
      filteredPriceAgreementOptions,
    } = this.props;
    // exception allowing all AU/NZ products for Canadian packers to account for previous fake packers
    const ingredientsOptions = getIngredientOptions(inputProducts, [
      {
        filterKey: 'COUNTRY',
        filterArgs:
          packerPlantOriginCountry === 'Canada'
            ? ['USA', 'Canada', 'Australia', 'New Zealand']
            : [packerPlantOriginCountry],
      },
    ]);
    ingredientsOptions.sort((a, b) => {
      const packerA = a.label.toLowerCase();

      const packerB = b.label.toLowerCase();
      return sortInAlphabeticalOrder(packerA, packerB);
    });

    const activeLineIndices = lineIndices?.filter(lineIdx => {
      return lines[lineIdx]?.active;
    });

    const isChicagoForm = checkIfChicagoForm({
      purchasingOffice: form.purchasingOffice,
      invoicingOffice: form.invoicingOffice,
    });

    const hasNoActiveLine = lineIndices?.every(lineIdx => !lines[lineIdx].active);

    return (
      <>
        {hasNoActiveLine && (
          <Flex justifyContent="flex-end" marginTop="0px !important">
            <IconButton
              onClick={onAddLine}
              disabled={!isInEditMode}
              colorScheme="actionSecondary"
              height="25px"
              minWidth="25px"
              borderRadius="32px"
            >
              <MdAdd className="international-order-line__icon--orange" />
            </IconButton>
          </Flex>
        )}
        <Box key={lines} backgroundColor="inherit">
          {lineIndices?.map(lineIdx => {
            const line = lines[lineIdx];
            return (
              <InternationalOrderLine
                {...{
                  buyCurrency,
                  buyUnitOfMeasureId,
                  destinationCountry: grinderDestinationCountry,
                  formType,
                  form,
                  key: lineIdx,
                  ingredientsOptions,
                  isChilled,
                  isInEditMode: isInEditMode && line.active,
                  hasExpectedProductionDate,
                  lineIndices: activeLineIndices,
                  line,
                  lines,
                  onAddLine,
                  onUpdateLine,
                  onRemoveLine,
                  onPPUChangeValue,
                  onPPURoundingDecimal,
                  originCountry: packerPlantOriginCountry,
                  packerId,
                  packerPlantId,
                  purchasingOffice,
                  purchaseOrderId,
                  purchaseOrderIndex,
                  requiredFields,
                  sellCurrency,
                  sellUnitOfMeasureId,
                  setErrorState: this.setErrorState,
                  shipmentDateEnd,
                  submitProductionDetails,
                  submitCLResults,
                  submitTemperatures,
                  submitPriceAdjustments,
                  packageWeights,
                  requiredFieldsLabels,
                  isUSADomestic,
                  isChicagoForm,
                  isLocked,
                  filteredPriceAgreementOptions,
                }}
              />
            );
          })}
          <Box className="col-md-12" style={{ paddingTop: '10px', marginLeft: 'none' }}>
            <ErrorMessage form={form} buyQuantityError={validationErrors.buyQuantity} {...this.props} />
            {validationErrors.dateError && <Alert status="error" title={validationErrors.dateError} />}
          </Box>
        </Box>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { load_sizes: loadSizes } = findEntitiesInState(state);
  return { loadSizes };
};

export default connect(mapStateToProps)(InternationalOrderLines);
