import { Box, Button, Group, Heading } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateConfirmationDocument, setConfirmationDocLoading } from '../../../actions/actions_orders';
import { downloadFromS3Link } from '../../../helpers/downloads';
import './ConfirmationButtons.scss';

class ConfirmationButtons extends Component {
  static propTypes = {
    confirmationDocument: PropTypes.shape({
      url: PropTypes.string,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    documentType: PropTypes.string.isRequired,
    poIds: PropTypes.arrayOf(PropTypes.number),
    setEmailProperties: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };

  downloadConfirmationDocument = () => {
    const { poIds, dispatch, documentType, token } = this.props;
    const payload = {
      purchase_order_ids: poIds,
    };

    dispatch(setConfirmationDocLoading(documentType));
    dispatch(generateConfirmationDocument(payload, documentType, token));
  };

  handleEmailButtonClick = () => {
    const { setEmailProperties, documentType, dispatch, token, poIds, confirmationDocument } = this.props;
    if (!confirmationDocument || !confirmationDocument.id) {
      dispatch(generateConfirmationDocument({ purchase_order_ids: poIds }, documentType, token));
    }
    setEmailProperties(documentType);
  };

  render() {
    const { documentType, confirmationDocument } = this.props;

    return (
      <Box className="confirmation-buttons" data-test="confirmation-buttons">
        <Box textAlign="center">
          <Heading as="h4">Confirmation of {documentType}</Heading>
        </Box>
        <Box textAlign="center">
          <Group spacing={2}>
            {!confirmationDocument.loading && !confirmationDocument.url && (
              <Button
                className="btn btn-success confirmation-buttons__button confirmation-buttons__button--primary"
                data-dismiss="modal"
                onClick={this.downloadConfirmationDocument}
              >
                Generate
              </Button>
            )}
            {confirmationDocument.loading && <img src="img/loader.gif" alt="loading" width={100} />}
            {confirmationDocument.url && (
              <Button
                variant="outline"
                color="primary.900"
                onClick={() => downloadFromS3Link(confirmationDocument.url)}
              >
                Download
              </Button>
            )}
            &nbsp;
            <Button
              data-test="confirmation-buttons-email"
              bg="secondary.700"
              color="white"
              px={10}
              data-dismiss="modal"
              onClick={this.handleEmailButtonClick}
            >
              Email
            </Button>
          </Group>
        </Box>
      </Box>
    );
  }
}

export default ConfirmationButtons;
