import React from 'react';
import PropTypes from 'prop-types';
import CustomFormControl from '../../core/CustomFormControl';
import { Box, HStack } from '@chakra-ui/react';
import { getOr } from '../../../helpers';

const propTypes = {
  onValueChange: PropTypes.func,
  form: PropTypes.shape({
    formulaName: PropTypes.string,
    formulaMarket: PropTypes.string,
    formulaDay: PropTypes.string,
    formulaDaysAveraged: PropTypes.number,
    formulaBasis: PropTypes.number,
  }),
  requiredFields: PropTypes.shape({
    form: PropTypes.arrayOf(PropTypes.string),
  }),
};

const FormulaSection = ({ onValueChange, form, requiredFields }) => {
  const requiredFieldsForm = getOr(requiredFields, 'form', []);

  return (
    <>
      <CustomFormControl label="Formula Name">
        <select
          className="form-control"
          onChange={e => onValueChange('formulaName', e.target.value)}
          value={form.formulaName}
        >
          <option value="">(none selected)</option>
          <option value="USDA National Weds 7d|usda,3,7">USDA National Weds 7d</option>
          <option value="USDA National Fri 7d|usda,5,7">USDA National Fri 7d</option>
          <option value="USDA National Weds 5d|usda,3,5">USDA National Weds 5d</option>
          <option value="USDA National Fri 5d|usda,5,5">USDA National Fri 5d</option>
        </select>
      </CustomFormControl>
      <CustomFormControl label="Market">
        <select
          className={requiredFieldsForm.includes('formulaMarket') ? 'form-control error' : 'form-control'}
          onChange={e => onValueChange('formulaMarket', e.target.value)}
          value={form.formulaMarket}
        >
          <option value="">(none)</option>
          <option value="USDA National">USDA National</option>
        </select>
      </CustomFormControl>
      <CustomFormControl label="Day">
        <select
          className="form-control"
          value={form.formulaDay}
          onChange={e => onValueChange('formulaDay', e.target.value)}
        >
          <option value="">(none)</option>
          <option value="1">Mon</option>
          <option value="2">Tue</option>
          <option value="3">Wed</option>
          <option value="4">Thu</option>
          <option value="5">Fri</option>
          <option value="6">Sat</option>
          <option value="0">Sun</option>
        </select>
      </CustomFormControl>
      <CustomFormControl label="Avg. Days">
        <input
          className="form-control"
          onChange={e => onValueChange('formulaDaysAveraged', e.target.value)}
          type="number"
          value={form.formulaDaysAveraged}
          onWheelCapture={e => {
            e.target.blur();
          }}
        />
      </CustomFormControl>
      <CustomFormControl label="Basis">
        <HStack spacing="10px">
          <p>
            <strong>$</strong>
          </p>
          <Box width="100%">
            <input
              className={requiredFieldsForm.includes('formulaBasis') ? 'form-control error' : 'form-control'}
              onChange={e => onValueChange('formulaBasis', e.target.value)}
              type="number"
              step=".01"
              max="0"
              value={form.formulaBasis}
              onWheelCapture={e => {
                e.target.blur();
              }}
            />
          </Box>
        </HStack>
      </CustomFormControl>
    </>
  );
};

FormulaSection.propTypes = propTypes;

export default FormulaSection;
