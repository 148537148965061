import React from 'react';
import PropTypes from 'prop-types';
// Local Dependencies
import UploadDocumentForm from './UploadDocumentForm';
import { convertToCamelCase } from '../../helpers';
import { Box, Flex, For, HStack } from '@chakra-ui/react';
import { DialogRoot, DialogContent, DialogBody, DialogHeader, DialogCloseTrigger } from '@/components/ui/dialog';

const propTypes = {
  autoSave: PropTypes.bool,
  closeModal: PropTypes.func,
  currentOrder: PropTypes.shape({
    loaded: PropTypes.bool,
    voyage: PropTypes.arrayOf(PropTypes.object),
  }),
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  poDocTypes: PropTypes.array.isRequired,
  stateForm: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

const UploadDocumentModal = props => {
  const { open, currentOrder, closeModal, ...rest } = props;
  return (
    <Box>
      <HStack wrap="wrap" gap="4">
        <For each={['center']}>
          {placement => (
            <DialogRoot
              open={open}
              onClose={closeModal}
              size="full"
              closeOnEscape
              closeOnInteractOutside
              key={placement}
              placement={placement}
            >
              <DialogContent minH={40} width="657px">
                <DialogHeader borderBottomWidth={1}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box as="h3" fontSize="18px" fontWeight="bold">
                      Upload Document
                    </Box>
                    <DialogCloseTrigger asChild onClick={closeModal} />
                  </Flex>
                </DialogHeader>
                <DialogBody>
                  <Flex justifyContent="center" alignItems="center">
                    <UploadDocumentForm {...{ currentOrder, closeModal, ...convertToCamelCase(rest) }} />
                  </Flex>
                </DialogBody>
              </DialogContent>
            </DialogRoot>
          )}
        </For>
      </HStack>
    </Box>
  );
};

UploadDocumentModal.propTypes = propTypes;

export default UploadDocumentModal;
