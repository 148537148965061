import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { sendRecurringOrders, sendSydneyOrdersInBulk } from '../../../../actions/actions_orders';
import Constants from '../../../../Constants';
import { defaultRequiredSydneyPOFormLabels, prepareSydneyOrders } from '../../../lib/PurchaseOrderFunctions';
import { createNewPurchaseOrder, initializeShipmentCosts, checkSydneyFormEligibility } from './helpers';
import { IsValidUSDomesticOrder } from '../../../../utils';
import { isEqual } from 'lodash';

const InternationalOrderFormContext = createContext();

function getGrinderValues(grinderUid, grinders) {
  const selectedGrinder = grinders.find(grinder => grinder.uid === grinderUid) || {};

  const {
    preferredUnitId: sellUnitOfMeasureId,
    defaultSellIncoterms: sellIncoterms,
    defaultSellCurrency: sellCurrency,
    destinationCountry: grinderDestinationCountry,
  } = selectedGrinder;
  return {
    sellUnitOfMeasureId,
    sellIncoterms,
    sellCurrency,
    grinderDestinationCountry,
  };
}

function getPackerValaues(packerId, packers) {
  const selectedPacker = packers.find(packer => packer.id === packerId) || {};

  const {
    preferredUnitId: buyUnitOfMeasureId,
    defaultBuyIncoterms: buyIncoterms,
    defaultBuyCurrency: buyCurrency,
  } = selectedPacker;

  return {
    buyUnitOfMeasureId,
    buyIncoterms,
    buyCurrency,
  };
}

// function listToObject(list, key) {
//   const configObject = list.reduce((agg, item, index) => {
//     return {
//       ...agg,
//       [item[key]]: {
//         ...item,
//         index,
//       },
//     };
//   }, {});
//   return configObject;
// }

function getMTCDefaultValue(formAttributes) {
  return formAttributes?.IS_AUSTRALIAN_DOMESTIC === Constants.FORM_ATTRIBUTES.IS_AUSTRALIAN_DOMESTIC;
}

const getCleanState = (packerPlantOriginCountry, grinders, packers, restOfProps) => {
  const { grinderDestinationCountry, ...grinderValues } = getGrinderValues(restOfProps.baseForm?.grinderUid, grinders);
  const packerValues = getPackerValaues(restOfProps?.baseForm?.packerId, packers);

  const cleanState = {
    isIneligible: false,
    confirmationOpened: false,
    requiredFields: [
      'buyIncoterms',
      'sellIncoterms',
      'buyCurrency',
      'buyUnitOfMeasureId',
      'sellUnitOfMeasureId',
      'sellCurrency',
      'packerId',
      'packerPlantId',
      'grinderUid',
      'priceType',
      'formulaName',
      'formulaMarket',
      'formulaDay',
      'formulaDaysAveraged',
      'formulaBasis',
      'contractStartDate',
      'contractEndDate',
      'foreignExchangerate',
      'loadContainerDetails',
    ],
    validationErrors: {
      buyQuantity: false,
    },
    // grinderDestinationCountry: 'USA',
    // packerPlantOriginCountry: 'USA',
    packerPlantOriginCountry,
    grinderDestinationCountry,
    poCounter: 1,
    form: {
      status: '',
      ordersPerdayOfWeek: [0, 0, 0, 0, 0, 0, 0],

      foreignExchangeRate: '',

      ...grinderValues,
      ...packerValues,

      internalComments: '',
      externalComments: '',
      confirmationOfPurchaseComments: '',
      confirmationOfSaleComments: '',

      halal: true,
      mtc: getMTCDefaultValue(restOfProps?.formAttributes),

      isChilled: false,
      isRecurring: false,
      isColdstoreRequired: restOfProps?.formAttributes?.HAS_VOYAGE_DETAILS,
      purchaseOrderIndices: [1],
      purchaseOrders: {
        1: createNewPurchaseOrder(),
      },
      fees: initializeShipmentCosts(),
    },
  };

  return cleanState;
};

export class InternationalOrderFormProvider extends React.Component {
  constructor(props) {
    super(props);
    const { children, ...restOfProps } = this.props;
    const { packers, grinders, packerPlants, baseForm } = restOfProps;
    const selectedPackerPlant = packerPlants.find(pp => pp.id === baseForm?.packerPlantId) || {};
    const { originCountry: packerPlantOriginCountry } = selectedPackerPlant;

    this.state = {
      ...JSON.parse(JSON.stringify(getCleanState(packerPlantOriginCountry, grinders, packers, restOfProps))),
      requiredFieldsLabels: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { formAttributes, baseForm, packerPlants, grinders, configs, packers } = this.props;
    const { form } = this.state;
    const selectedPackerPlant = packerPlants.find(pp => pp.id === baseForm?.packerPlantId) || {};
    const { originCountry: packerPlantOriginCountry } = selectedPackerPlant;

    const labelCheckBaseForm = { ...baseForm };
    const prevPropsLabelCheckBaseForm = { ...prevProps.baseForm };
    if (labelCheckBaseForm && prevPropsLabelCheckBaseForm) {
      delete labelCheckBaseForm.buyIncoterms;
      delete prevPropsLabelCheckBaseForm.buyIncoterms;
      Object.keys(labelCheckBaseForm).map(labelCheckBaseFormKey => {
        if (!defaultRequiredSydneyPOFormLabels.includes(labelCheckBaseFormKey))
          delete labelCheckBaseForm[labelCheckBaseFormKey];
        return null;
      });
      Object.keys(prevPropsLabelCheckBaseForm).map(prevPropsLabelCheckBaseFormKey => {
        if (!defaultRequiredSydneyPOFormLabels.includes(prevPropsLabelCheckBaseFormKey))
          delete prevPropsLabelCheckBaseForm[prevPropsLabelCheckBaseFormKey];
        return null;
      });
    }
    if (
      !isEqual(prevPropsLabelCheckBaseForm, labelCheckBaseForm) ||
      prevState.form.buyCurrency !== form.buyCurrency ||
      prevState.form.sellCurrency !== form.sellCurrency
    ) {
      const fullForm = { ...baseForm, ...form };
      if (fullForm.buyCurrency === fullForm.sellCurrency) {
        fullForm.foreignExchangeRate = Constants.DEFAULT_EXCHANGE_RATE;
      }
      const { requiredFields } = this.state;
      const { validateRequiredFields } = this.props;
      const requiredFieldsErrors = validateRequiredFields(fullForm, requiredFields, true, configs);
      this.setState({
        requiredFieldsLabels: requiredFieldsErrors,
      });
    }

    if (
      prevProps?.baseForm?.grinderUid !== baseForm?.grinderUid ||
      prevProps?.baseForm?.packerId !== baseForm?.packerId
    ) {
      const { grinderDestinationCountry, ...grinderValues } = getGrinderValues(baseForm?.grinderUid, grinders);
      const packerValues = getPackerValaues(baseForm?.packerId, packers);
      this.setState(previousState => {
        return {
          packerPlantOriginCountry,
          grinderDestinationCountry,
          form: {
            ...previousState.form,
            ...grinderValues,
            ...packerValues,
          },
        };
      });
    }

    if (!isEqual(prevProps.formAttributes, formAttributes)) {
      this.setState(previousState => {
        return {
          form: {
            ...previousState.form,
            isColdstoreRequired: formAttributes?.HAS_VOYAGE_DETAILS,
          },
        };
      });
    }
  }

  handleChangeObjectList = (parentKey, listName, key, index, value, setString = false) => {
    const { form } = this.state;
    let formattedValue = value || '';
    if (value === 0) {
      formattedValue = value;
    } else if (value && value.toString && setString === true) {
      formattedValue = value.toString();
    } else if (setString) {
      formattedValue = '';
    }

    let list = [];
    if (parentKey === 'form') {
      list = get(form, listName, []);
    } else {
      list = get(form, `${parentKey}.${listName}`, []);
    }

    const updatedList = list.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [key]: formattedValue,
        };
      }
      return item;
    });

    if (parentKey === 'form') {
      this.setState(prevState => {
        return {
          form: {
            ...prevState.form,
            [listName]: updatedList,
          },
        };
      });
      return;
    }

    this.setState(prevState => {
      return {
        form: {
          ...prevState.form,
          [parentKey]: {
            ...form[parentKey],
            [listName]: updatedList,
          },
        },
      };
    });
  };

  handleClearFormData = () => {
    const { clearFormData, ...restOfProps } = this.props;
    const { packers, grinders, packerPlants, baseForm } = restOfProps;
    const selectedPackerPlant = packerPlants.find(pp => pp.id === baseForm?.packerPlantId) || {};
    const { originCountry: packerPlantOriginCountry } = selectedPackerPlant;
    const stateCopy = JSON.parse(
      JSON.stringify(getCleanState(packerPlantOriginCountry, grinders, packers, restOfProps))
    );

    this.setState(stateCopy);
    clearFormData();
  };

  handleStateChangesCallback = (key, e) => {
    const { onBuyIncoTermsChange } = this.props;
    // add your rules here for lifting state here
    if (['buyIncoterms'].includes(key)) {
      onBuyIncoTermsChange(e ? e.value : '');
    }
  };

  handleChangeValue = (key, e) => {
    if (['packer', 'grinder'].includes(key)) {
      if (e) {
        const list = get(this.props, `${key}s`, []);
        const value = list.find(el => el.id === e.value);
        return this.setState({
          [key]: value,
        });
      }
      return this.setState(prevState => {
        return {
          form: {
            ...prevState.form,
            [`${key}Id`]: e ? e.value : '',
          },
        };
      });
    }
    this.setState(prevState => {
      return {
        form: {
          ...prevState.form,
          [key]: get(e, 'value') || e || '',
        },
      };
    });
    this.handleStateChangesCallback(key, e);
    return null;
  };

  onFXRoundingDecimal = (key, e) => {
    // format FFX input to 5 decimal places
    const roundedValue = parseFloat(e);

    if (!isNaN(roundedValue)) {
      this.handleChangeValue(key, roundedValue.toFixed(5));
    }
  };

  onFXChangeValue = (key, e) => {
    const {
      form: { foreignExchangeRate },
    } = this.state;
    if (isNaN(e)) {
      this.handleChangeValue(key, foreignExchangeRate);
      return;
    }

    let roundedNumber = null;

    if (typeof e === 'string') {
      const [whole, part] = e.split('.');
      // checking and limiting FX Rate input to 5 decimal places
      if (e.lastIndexOf('.') !== -1 && part.length > 5) {
        roundedNumber = parseFloat(`${whole}.${part.slice(0, 5)}`);
      }
    }
    const value = roundedNumber != null ? roundedNumber : e;

    this.handleChangeValue(key, value);
  };

  onBooleanValueChange = (key, index) => {
    const { form } = this.state;
    const formCopy = JSON.parse(JSON.stringify(form));

    if (index !== undefined) {
      if (key === 'frozen') {
        formCopy.lines[index].chilled = formCopy.lines[index].frozen;
        formCopy.lines[index].frozen = !formCopy.lines[index].frozen;
      } else {
        formCopy.lines[index][key] = !formCopy.lines[index][key];
      }
    } else {
      formCopy[key] = !formCopy[key];
    }

    this.setState({
      form: formCopy,
    });
  };

  onRecurringValueChange = (key, e, index) => {
    const stateCopy = JSON.parse(JSON.stringify(this.state));

    if (key === 'ordersPerDayOfWeek') {
      stateCopy.form.ordersPerDayOfWeek[index] = e ? Number(e.value) : '';
    } else {
      stateCopy.form[key] = e || '';
    }
    this.setState(stateCopy);
  };

  closeModal = shouldClearForm => {
    const { confirmationOpened } = this.state;
    this.setState({
      confirmationOpened: !confirmationOpened,
    });
    if (shouldClearForm) {
      this.handleClearFormData();
    }
  };

  saveAndSend = (form, submit) => {
    const { token, dispatch } = this.props;

    // Update
    // Form status
    // Price type - business is limited to only 'spot' priceType on international shipment
    //              delete this once priceType is extended
    const updatedForm = {
      ...form,
      status: submit ? Constants.ORDER_STATUSES.ORDERED : Constants.ORDER_STATUSES.PENDING,
      priceType: 'spot',
    };

    // NOTE: This form name ("singleIngredientForm") is out of date.
    // It supports multiple ingredients, and is used for Sydney orders
    if (!form.isRecurring) {
      const preparedOrders = prepareSydneyOrders(updatedForm);
      dispatch(sendSydneyOrdersInBulk(preparedOrders, token));
    } else {
      const recurringOrders = prepareSydneyOrders(updatedForm);

      const recurringOrdersWithRules = recurringOrders.map(recurringOrder => ({
        purchaseOrder: recurringOrder,
        rules: {
          startDate: recurringOrder.recurringDateStart,
          endDate: recurringOrder.recurringDateEnd,
          weeklyLoads: recurringOrder.days,
        },
      }));

      dispatch(sendRecurringOrders(recurringOrdersWithRules, token));
    }
  };

  confirmForm = send => {
    // TODO FixMe, code duplicated in EditInternationalOrder validateForm
    const { form, requiredFields, validationErrors } = this.state;
    const { validateRequiredFields, baseForm } = this.props;
    const fullForm = { ...baseForm, ...form };

    if (fullForm.buyCurrency === fullForm.sellCurrency) {
      fullForm.foreignExchangeRate = Constants.DEFAULT_EXCHANGE_RATE;
    }
    // this is the first step of validation errors on international order creation
    const requiredFieldsErrors = validateRequiredFields(fullForm, requiredFields, false, this.props.configs);

    if (requiredFieldsErrors.error) {
      this.setState({
        validationErrors: {
          ...validationErrors,
          requiredFields: requiredFieldsErrors,
        },
      });
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    } else if (send) {
      this.setState({
        confirmationOpened: true,
      });
    } else {
      this.saveAndSend(fullForm, false);
    }
  };

  handleUpdatePurchaseOrder = po => {
    this.setState(prevState => {
      return {
        form: {
          ...prevState.form,
          purchaseOrders: {
            ...prevState.form.purchaseOrders,
            [po.idx]: po,
          },
        },
      };
    });
  };

  handleAddPurchaseOrder = po => {
    const { poCounter, form } = this.state;
    const newPOIndex = poCounter + 1;

    // The field dateType is managed by the child component state (purchase order card) after
    // it is initialized here:
    const newPO = createNewPurchaseOrder({
      ...po,
      idx: newPOIndex,
      dateType: form.isRecurring ? 'recurring' : 'shipment',
    });

    this.setState(prevState => {
      return {
        poCounter: newPOIndex,
        form: {
          ...prevState.form,
          purchaseOrderIndices: [...form.purchaseOrderIndices, newPOIndex],
          purchaseOrders: {
            ...prevState.form.purchaseOrders,
            [newPOIndex]: newPO,
          },
        },
      };
    });
  };

  handleCopyPurchaseOrder = purchaseOrderIdx => {
    const { poCounter, form } = this.state;
    const { purchaseOrders } = form;
    const duplicate = JSON.parse(JSON.stringify(purchaseOrders[purchaseOrderIdx]));
    const newPOIndex = poCounter + 1;

    // Add duplicate with new index set by counter
    this.setState(prevState => {
      return {
        poCounter: newPOIndex,
        form: {
          ...prevState.form,
          purchaseOrderIndices: [...prevState.form.purchaseOrderIndices, newPOIndex],
          purchaseOrders: {
            ...prevState.form.purchaseOrders,
            [newPOIndex]: {
              ...duplicate,
              idx: newPOIndex,
            },
          },
        },
      };
    });
  };

  handleRemovePurchaseOrder = purchaseOrderIdx => {
    const { form } = this.state;
    const { purchaseOrders, purchaseOrderIndices } = form;

    if (purchaseOrderIndices.length === 1) {
      return;
    }

    delete purchaseOrders[purchaseOrderIdx];

    this.setState(prevState => {
      return {
        form: {
          ...prevState.form,
          purchaseOrderIndices: purchaseOrderIndices.filter(idx => idx !== purchaseOrderIdx),
          purchaseOrders,
        },
      };
    });
  };

  handleToggleChange = fieldName => {
    const { form } = this.state;
    const { configs, formAttributes } = this.props;

    const value = !get(form, fieldName);

    let isIneligible = false;
    if (fieldName === 'isChilled') {
      isIneligible = !checkSydneyFormEligibility(formAttributes, value);
      // allow create us domestic orders
      if (IsValidUSDomesticOrder(configs)) {
        isIneligible = false;
      }
    }

    this.setState(prevState => {
      return {
        form: {
          ...prevState.form,
          [fieldName]: value,
        },
        isIneligible,
      };
    });
  };

  render() {
    const {
      state,
      setState,
      handleChangeObjectList,
      handleClearFormData,
      onFXRoundingDecimal,
      onFXChangeValue,
      handleStateChangesCallback,
      handleChangeValue,
      onBooleanValueChange,
      onRecurringValueChange,
      closeModal,
      confirmForm,
      saveAndSend,
      handleUpdatePurchaseOrder,
      handleAddPurchaseOrder,
      handleCopyPurchaseOrder,
      handleRemovePurchaseOrder,
      handleToggleChange,
    } = this;

    const { requiredFieldsLabels } = state;
    const { children, ...restOfProps } = this.props;

    return (
      <InternationalOrderFormContext.Provider
        value={{
          state,
          setState,
          handleChangeObjectList,
          handleClearFormData,
          onFXRoundingDecimal,
          onFXChangeValue,
          handleStateChangesCallback,
          handleChangeValue,
          onBooleanValueChange,
          onRecurringValueChange,
          closeModal,
          confirmForm,
          saveAndSend,
          handleUpdatePurchaseOrder,
          handleAddPurchaseOrder,
          handleCopyPurchaseOrder,
          handleRemovePurchaseOrder,
          handleToggleChange,
          requiredFieldsLabels,
          ...restOfProps,
        }}
      >
        {children}
      </InternationalOrderFormContext.Provider>
    );
  }
}
InternationalOrderFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  baseForm: PropTypes.shape({
    grinderUid: PropTypes.string,
    packerId: PropTypes.string,
    packerPlantId: PropTypes.string,
    purchasingOffice: PropTypes.string,
    singleIngredientForm: PropTypes.bool,
    productType: PropTypes.string,
    voyage: PropTypes.shape({
      dischargePortId: PropTypes.number,
    }),
  }),
  clearFormData: PropTypes.func,
  formAttributes: PropTypes.shape({
    HAS_PACKER_AND_GRINDER: PropTypes.bool,
    HAS_VOYAGE_DETAILS: PropTypes.bool,
    IS_AMERICAN_DOMESTIC: PropTypes.bool,
    IS_AUSTRALIAN_DOMESTIC: PropTypes.bool,
    IS_EUROPE_DOMESTIC: PropTypes.bool,
    RECURRING_ENABLED: PropTypes.bool,
  }),
  onBuyIncoTermsChange: PropTypes.func,
  validateRequiredFields: PropTypes.func,
  validationErrors: PropTypes.shape({
    requiredFields: PropTypes.instanceOf(Object),
    dates: PropTypes.bool,
  }),
  dispatch: PropTypes.func,
  currencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fecs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      // Add other properties as needed
    })
  ).isRequired,
  grinders: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      preferredUnitId: PropTypes.string,
      defaultSellIncoterms: PropTypes.string,
      defaultSellCurrency: PropTypes.string,
      destinationCountry: PropTypes.string,
    })
  ).isRequired,
  incoTerms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inputProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      // Add other properties as needed
    })
  ).isRequired,
  loadSizes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      // Add other properties as needed
    })
  ),
  packerPlants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      originCountry: PropTypes.string,
      // Add other properties as needed
    })
  ).isRequired,
  packers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      preferredUnitId: PropTypes.string,
      defaultBuyIncoterms: PropTypes.string,
      defaultBuyCurrency: PropTypes.string,
    })
  ).isRequired,
  ports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      // Add other properties as needed
    })
  ).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  token: PropTypes.string,
  unitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      // Add other properties as needed
    })
  ).isRequired,
  packageWeights: PropTypes.arrayOf(PropTypes.Object),
  configs: PropTypes.arrayOf(PropTypes.Object),
};

export const withInternationalOrderFormContext = Child => props => {
  return (
    <InternationalOrderFormContext.Consumer>
      {context => {
        return <Child {...props} {...context} />;
      }}
    </InternationalOrderFormContext.Consumer>
  );
};
