import React from 'react';
import { Box, Text, List,  } from '@chakra-ui/react';
import { Alert } from '../../ui/alert';

const OrderFormFinalizeMessage = ({ pendingLoaded, fastLoaded, copies, latestOrders, reset }) => {
  return (
    <Alert rounded="md" status="success" closable onClose={reset}>
      <Box>
        {pendingLoaded && (
          <Text>
            Successfully created {copies} pending order{copies > 1 ? 's' : false}
            <br />
          </Text>
        )}
        {fastLoaded && (
          <Box>
            Successfully sent {copies} order{copies > 1 ? 's' : false}
            <List.Root styleType="none">
              {latestOrders.map(order => {
                return (
                  <List.Item key={order.grinder_po_number}>
                    Grinder PO #:{' '}
                    {order.grinder_po_number
                      ? order.grinder_po_number
                      : order.grinder.po_strategy.toLowerCase() === 'manual' && 'to be assigned'}
                  </List.Item>
                );
              })}
            </List.Root>
          </Box>
        )}
      </Box>
    </Alert>
  );
};

export default OrderFormFinalizeMessage;
