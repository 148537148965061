import { Button, Stack } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../Constants';
import { selectCurrentUser } from '../../../reducers/reducer_user';
import SelectField from '../../basic/SelectField';
import './TransferOrderModal.scss';
import { MdAdd, MdOutlineClose } from 'react-icons/md';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Field } from '@/components/ui/field';
import { toaster } from '@/components/ui/toaster';
import PropTypes from 'prop-types';

const TransferOrderModal = ({ children, purchaseOrderId, onTransferSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { token } = useSelector(selectCurrentUser);
  const axiosConfig = useRef({
    headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json' },
    data: '{}',
  });
  const [grinders, setGrinders] = useState([]);
  const [grinder, setGrinder] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [documents, setDocuments] = useState([]);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const loadGrinders = ({ productType, destinationCountry }) =>
    axios
      .get(
        `${Constants.URL}grinders?product_type=${productType}&destination_country=${destinationCountry}`,
        axiosConfig.current
      )
      .then(response => setGrinders(response.data.map(g => ({ label: g.name, value: g.uid }))));

  const loadPurchaseOrder = () =>
    axios
      .get(`${Constants.URL}order_management/purchase_orders/${purchaseOrderId}/netsuite_all`, axiosConfig.current)
      .then(response => {
        const po = response.data;
        setPurchaseOrder(po);
        loadGrinders({
          destinationCountry: po.grinder.destination_country,
          productType: po.product_type,
        });
      });

  const transferPurchaseOrder = () => {
    if (!grinder) {
      toaster.create({
        title: 'Please select a grinder.',
        status: 'error',
      });
      return;
    }
    axios
      .post(
        `${Constants.URL}order_management/purchase_orders/${purchaseOrderId}/transfer`,
        {
          grinder_uid: grinder,
          document_ids: documents,
        },
        axiosConfig.current
      )
      .then(response => {
        const po = response.data;
        onClose();
        const delayMS = 3000;
        toaster.create({
          title: `Purchase order transferred successfully!`,
          status: 'success',
        });
        setTimeout(() => {
          window.open(
            `/order/search?internal_po_number=${po.internal_po_number}&po_lookup_field=internal_po_number&auto_open_po_card_purchase_order_id=${po.id}`,
            '_blank'
          );
        }, delayMS);
        if (onTransferSuccess && typeof onTransferSuccess === 'function') {
          onTransferSuccess();
        }
      });
  };

  useEffect(() => {
    if (isOpen === false) {
      setGrinders([]);
      setGrinder(null);
      setPurchaseOrder(null);
      setDocuments([]);
    } else if (isOpen === true) {
      loadPurchaseOrder();
    }
  }, [isOpen]);

  return (
    <>
      {!!children && typeof children === 'function' && children({ onOpen })}
      <DialogRoot open={isOpen} size="xl" onClose={onClose} centered>
        <DialogContent className="transfer-order-modal">
          <DialogHeader className="header">Order Status Transferred</DialogHeader>
          <DialogCloseTrigger asChild onClick={onClose} />
          <DialogBody>
            <Stack p={2} spacing={4}>
              <SelectField
                label="Grinder"
                name="select-grinder"
                defaultValue={grinder}
                options={grinders}
                placeholder="Select"
                onChange={e => setGrinder(e.value)}
              />

              {!!purchaseOrder && (purchaseOrder.documents || []).length > 0 && (
                <Field label="Document to be transferred">
                  {purchaseOrder.documents.map(({ id, doc_type }) => (
                    <Button
                      key={id}
                      m={2}
                      onClick={() => {
                        const newDocuments = [...documents];
                        if (documents.includes(id)) {
                          setDocuments(newDocuments.filter(d => d !== id));
                        } else {
                          setDocuments([...newDocuments, id]);
                        }
                      }}
                      colorScheme={documents.includes(id) ? 'blue' : 'gray'}
                      variant="outline"
                    >
                      {doc_type}
                      {documents.includes(id) ? <MdOutlineClose /> : <MdAdd />}
                    </Button>
                  ))}
                </Field>
              )}
            </Stack>
          </DialogBody>
          <DialogFooter className="footer">
            <Button variant="outline" colorScheme="actionPrimary" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="actionPrimary" onClick={transferPurchaseOrder}>
              Transfer
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export default TransferOrderModal;

TransferOrderModal.propTypes = {
  children: PropTypes.func,
  purchaseOrderId: PropTypes.number,
  onTransferSuccess: PropTypes.func,
};
