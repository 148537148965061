import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapsible, HStack, IconButton, Textarea, VStack } from '@chakra-ui/react';
import { withInternationalOrderFormContext } from '../InternationalOrderForm/InternationalOrderFormContext';
import { Field } from '@/components/ui/field';
import { MdAdd, MdOutlineRemove } from 'react-icons/md';
import './Comments.scss';

class Comments extends Component {
  static propTypes = {
    onChangeValue: PropTypes.func,
    form: PropTypes.shape({
      confirmationOfPurchaseComments: PropTypes.string,
      confirmationOfSaleComments: PropTypes.string,
      externalComments: PropTypes.string,
      internalComments: PropTypes.string,
    }),
  };

  state = {
    showInternalComments: false,
    showExternalComments: false,
  };

  handleToggleInternalComments = () => {
    const { showInternalComments } = this.state;
    this.setState({
      showInternalComments: !showInternalComments,
    });
  };

  handleToggleExternalComments = () => {
    const { showExternalComments } = this.state;
    this.setState({
      showExternalComments: !showExternalComments,
    });
  };

  render() {
    const { form } = this.props.state ? this.props.state : this.props;
    const onChangeValue = this.props.handleChangeValue;
    const { showExternalComments, showInternalComments } = this.state;
    return (
      <VStack gap="26px" align="stretch" marginTop="20px !important">
        <VStack tack align="stretch">
          <Field label="Confirmation of Purchase Notes" htmlFor="confirmationNotes">
            <Textarea
              id="confirmationNotes"
              placeholder="Add comment here..."
              backgroundColor="white"
              value={form.confirmationOfPurchaseComments}
              onChange={e => onChangeValue('confirmationOfPurchaseComments', e.target.value)}
              rows="5"
            />
          </Field>
        </VStack>
        <VStack align="stretch">
          <Field label="Confirmation of Sale Notes" htmlFor="confirmationSaleNotes">
            <Textarea
              id="confirmationSaleNotes"
              placeholder="Add comment here..."
              backgroundColor="white"
              value={form.confirmationOfSaleComments}
              onChange={e => onChangeValue('confirmationOfSaleComments', e.target.value)}
              rows="5"
            />
          </Field>
        </VStack>
        <VStack align="stretch">
          <Collapsible.Root onOpenChange={this.handleToggleInternalComments}>
            <Collapsible.Trigger paddingY="3">
              <HStack role="presentation" className="comments__collapse-header">
                <IconButton
                  colorScheme="actionSecondary"
                  height="35px"
                  width="35px"
                  borderRadius="18px"
                  minWidth="auto"
                >
                  {/* <i className={`la ${showInternalComments ? 'la-minus' : 'la-plus'}`} /> */}
                  {showInternalComments ? <MdOutlineRemove /> : <MdAdd />}
                </IconButton>
                <Field htmlFor="internalComments">Internal Comments</Field>
              </HStack>
            </Collapsible.Trigger>
            <Collapsible.Content>
              <Textarea
                id="internalComments"
                placeholder="Add comment here..."
                backgroundColor="white"
                value={form.internalComments}
                onChange={e => onChangeValue('internalComments', e.target.value)}
                rows="5"
              />
            </Collapsible.Content>
          </Collapsible.Root>
        </VStack>
        <VStack align="stretch">
          <Collapsible.Root onOpenChange={this.handleToggleExternalComments}>
            <Collapsible.Trigger paddingY="3">
              <HStack role="presentation" className="comments__collapse-header">
                <IconButton
                  colorScheme="actionSecondary"
                  height="35px"
                  width="35px"
                  borderRadius="18px"
                  minWidth="auto"
                >
                  {/* <i className={`la ${showExternalComments ? 'la-minus' : 'la-plus'}`} /> */}
                  {showExternalComments ? <MdOutlineRemove /> : <MdAdd />}
                </IconButton>
                <Field htmlFor="externalComments">External Comments</Field>
              </HStack>
            </Collapsible.Trigger>
            <Collapsible.Content>
              <Textarea
                id="externalComments"
                placeholder="Add comment here..."
                backgroundColor="white"
                value={form.externalComments}
                onChange={e => onChangeValue('externalComments', e.target.value)}
                rows="5"
              />
            </Collapsible.Content>
          </Collapsible.Root>
        </VStack>
      </VStack>
    );
  }
}

export default withInternationalOrderFormContext(Comments);
