import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Grid, Heading, Separator } from '@chakra-ui/react';
import Loading from '../../../basic/Loading/Loading';
import ConfirmationPurchaseOrdersGrid from '../ConfirmationPurchaseOrdersGrid/ConfirmationPurchaseOrdersGrid';
import './ConfirmationDetails.scss';

const propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    halal: PropTypes.bool.isRequired,
    mtc: PropTypes.bool.isRequired,
    buyIncoterms: PropTypes.string,
    sellIncoterms: PropTypes.string,
    foreignExchangeRate: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  grinder: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  packerPlant: PropTypes.shape({
    name: PropTypes.string,
    est: PropTypes.string,
  }).isRequired,
  buyer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
    })
  ),
  internalPoLabel: PropTypes.string.isRequired,
};

const ConfirmationDetails = ({ order, loading, lines, grinder, packerPlant, buyer, internalPoLabel }) => {
  return (
    <Box className="confirmation-details">
      {loading && <Loading />}
      {!loading && (
        <Box>
          <Grid templateColumns="repeat(3, 1fr)" gap="6" className="confirmation-details__row">
            {/* <!-- COLUMN #1 --> */}
            <Box md={4} className="confirmation-details__col">
              {/* <!-- GRINDER PO # --> */}
              {order.id && (
                <Box className="confirmation-details__data-line">
                  <Box className="confirmation-details__data-label">Grinder PO #</Box>
                  <Box className="confirmation-details__data-value">{order.id}</Box>
                </Box>
              )}
              {/* <!-- GRINDER --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label">Grinder</Box>
                <Box className="confirmation-details__data-value">{grinder.name}</Box>
              </Box>
              {/* <!-- ADDRESS --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label">Address</Box>
                <Box className="confirmation-details__data-value">
                  {`${get(grinder, 'address.city')}, ${get(grinder, 'address.state')}, ${get(
                    grinder,
                    'address.country'
                  )}`}
                </Box>
              </Box>
              {/* <!-- BUYER --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label">Buyer</Box>
                <Box className="confirmation-details__data-value">{`${buyer.firstName} ${buyer.lastName}`}</Box>
              </Box>
            </Box>
            {/* <!-- COLUMN #2 --> */}
            <Box md={4} className="confirmation-details__col">
              {/* <!-- PACKER PLANT # --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label">Packer</Box>
                <Box className="confirmation-details__data-value">{packerPlant.name}</Box>
              </Box>
              {/* <!-- EST # --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label">EST #</Box>
                <Box className="confirmation-details__data-value">{packerPlant.est}</Box>
              </Box>
              {/* <!-- ADDRESS --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label">Address</Box>
                <Box className="confirmation-details__data-value">
                  {`${get(packerPlant, 'address.city')}, ${get(packerPlant, 'address.state')}, ${get(
                    packerPlant,
                    'address.country'
                  )}`}
                </Box>
              </Box>
            </Box>
            {/* <!-- COLUMN #3 --> */}
            <Box md={4} className="confirmation-details__col">
              {/* <!-- INCO TERM BUY # --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label confirmation-details__data-label--large">
                  Inco Term Buy
                </Box>
                <Box className="confirmation-details__data-value">{order.buyIncoterms}</Box>
              </Box>
              {/* <!-- INCO TERM SELL # --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label confirmation-details__data-label--large">
                  Inco Term Sell
                </Box>
                <Box className="confirmation-details__data-value">{order.sellIncoterms}</Box>
              </Box>
              {/* <!-- FOREIGN EXCHANGE RATE --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label confirmation-details__data-label--large">FX Rate</Box>
                <Box className="confirmation-details__data-value">
                  {(parseFloat(order.foreignExchangeRate) || 1).toFixed(5)}
                </Box>
              </Box>
              {/* <!-- HALAL --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label confirmation-details__data-label--large">Halal</Box>
                <Box className="confirmation-details__data-value">{order.halal ? 'Yes' : 'No'}</Box>
              </Box>
              {/* <!-- MTC --> */}
              <Box className="confirmation-details__data-line">
                <Box className="confirmation-details__data-label confirmation-details__data-label--large">MTC</Box>
                <Box className="confirmation-details__data-value">{order.mtc ? 'Yes' : 'No'}</Box>
              </Box>
            </Box>
          </Grid>
          <Separator marginTop="20px" marginBottom="10px" />
          {/* <!-- HEADER --> */}
          <Heading as="h2" className="confirmation-details__header">
            Purchase Order Lines
          </Heading>
          {/* <!-- GRID --> */}
          <ConfirmationPurchaseOrdersGrid
            rows={lines}
            internalPoLabel={internalPoLabel}
            grinder={grinder}
            display="flex"
            justifyContent="center"
          />
        </Box>
      )}
    </Box>
  );
};

ConfirmationDetails.propTypes = propTypes;

export default ConfirmationDetails;
