import React from 'react';
import PropTypes from 'prop-types';
import { Box, GridItem, Heading, SimpleGrid, Text, Flex, IconButton } from '@chakra-ui/react';

import { commify } from '../../../functions';
import SelectField from '../../basic/SelectField';
import './FECSelection.scss';
import POFormFieldLabel from './edit-form/POFormFieldLabel';
import { getOr } from '../../../helpers';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const fullFilter = (option, searchText) => {
  if (
    option.data.fecDescription.toLowerCase().includes(searchText.toLowerCase()) ||
    option.data.fecConfirmationNumber.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true;
  }
  return false;
};

const propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    fecDescription: PropTypes.string,
    fecConfirmationNumber: PropTypes.string,
    provider: PropTypes.string,
    spotRate: PropTypes.number,
    fecRate: PropTypes.number,
    fecMaturityDate: PropTypes.string,
    totalFecValue: PropTypes.number,
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      fecDescription: PropTypes.string,
      fecConfirmationNumber: PropTypes.string,
    })
  ),
  filter: PropTypes.func,
  requiredFields: PropTypes.shape({
    form: PropTypes.arrayOf(PropTypes.string),
  }),
};

const FECSelection = ({ value, onChange, options = [], disabled, filter = x => x, requiredFields }) => {
  const valueCopy = { ...value };
  // TODO: The next few lines setting a label are due to a shortcoming with the version of
  // react-select that we're using. `getOptionLabel` is what we want to be using, but in this
  // version the library doesn't actually do anything with that property.
  const filteredOptions = options
    .filter(filter)
    .map(opt => ({ ...opt, label: `${opt.fecDescription}(${opt.fecConfirmationNumber})` }));
  if (value && !value.label) {
    valueCopy.label = `${value.fecDescription}(${value.fecConfirmationNumber})`;
  }

  const requiredFieldsForm = getOr(requiredFields, 'form', []);

  return (
    <Flex direction="column">
      <POFormFieldLabel
        label="FEC Number"
        htmlFor="select-fec-number"
        fieldName="fec"
        tooltip="Select the FEC number if the currency is not AUD."
      />
      <SimpleGrid columns={1} className="fec-simple-grid">
        <GridItem>
          <SelectField
            disabled={disabled}
            name="select-fec-number"
            value={valueCopy}
            options={filteredOptions}
            placeholder="FEC"
            filterOption={fullFilter}
            onChange={onChange}
            isClearable={false}
            className={requiredFieldsForm.includes('fec') ? 'error' : ''}
          />
        </GridItem>
      </SimpleGrid>
      <Box className="group-fec-details">
        <Heading as="h4">
          FEC Details&nbsp;
          <Tooltip content="Displays details based on the FEC number selection." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
        </Heading>
        <SimpleGrid columns={1}>
          <GridItem>
            <Box as="span">
              <Text fontWeight="bold">
                Provider&nbsp;
                <Tooltip content="Specifies the provider of the Forward Exchange Contract." positioning={{ placement: 'right-end' }}>
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
                :
              </Text>
              {(valueCopy && valueCopy.provider) || ''}
            </Box>
          </GridItem>
          <GridItem>
            <Box as="span">
              <Text fontWeight="bold">
                Spot Rate&nbsp;
                <Tooltip content="Displays the spot rate for the FEC." positioning={{ placement: 'right-end' }}>
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
                :
              </Text>
              {(valueCopy && valueCopy.spotRate) || ''}
            </Box>
          </GridItem>
          <GridItem>
            <Box as="span">
              <Text fontWeight="bold">
                FEC Rate&nbsp;
                <Tooltip
                  content="Displays the agreed exchange rate in the Forward Exchange Contract."
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
                :
              </Text>
              {(valueCopy && valueCopy.fecRate) || ''}
            </Box>
          </GridItem>
          <GridItem>
            <Box as="span">
              <Text fontWeight="bold">
                FEC Maturity Date&nbsp;
                <Tooltip content="Specifies the maturity date of the Forward Exchange Contract." positioning={{ placement: 'right-end' }}>
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
                :
              </Text>
              {(valueCopy && valueCopy.fecMaturityDate) || ''}
            </Box>
          </GridItem>
          <GridItem>
            <Box as="span">
              <Text fontWeight="bold">
                Total FEC Value&nbsp;
                <Tooltip content="Displays the total value of the Forward Exchange Contract." positioning={{ placement: 'right-end' }}>
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
                :
              </Text>
              {(valueCopy && commify(valueCopy.totalFecValue, 2)) || ''}
            </Box>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

FECSelection.propTypes = propTypes;

export default FECSelection;
