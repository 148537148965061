import React from 'react';
import PropTypes from 'prop-types';
import Datetime from '@/components/core/DatePicker';
import CustomFormControl from '../../core/CustomFormControl';
import { getOr } from '../../../helpers';

const propTypes = {
  form: PropTypes.shape({
    contractStartDate: PropTypes.string,
    contractEndDate: PropTypes.string,
  }),
  onChangeValue: PropTypes.func,
  requiredFields: PropTypes.shape({
    form: PropTypes.arrayOf(PropTypes.string),
  }),
};

const ContractSection = ({ form, onChangeValue, requiredFields }) => {
  const contractStartDateInputProps = {
    placeholder: 'Contract Start',
    className: 'form-control m-input',
  };
  const contractEndDateInputProps = {
    placeholder: 'Contract End',
    className: 'form-control m-input',
  };

  const requiredFieldsForm = getOr(requiredFields, 'form', []);

  return (
    <>
      <CustomFormControl label="Contract Start Date">
        <Datetime
          id="contractStartDate"
          inputProps={contractStartDateInputProps}
          closeOnSelect
          value={form.contractStartDate}
          className={requiredFieldsForm.includes('contractStartDate') ? 'error' : ''}
          timeFormat={false}
          dateFormat="YYYY-MM-DD"
          onChange={e => {
            onChangeValue('contractStartDate', e._isAMomentObject ? e.format('YYYY-MM-DD') : e); // eslint-disable-line no-underscore-dangle
          }}
          maxDate={form.contractEndDate ? new Date(form.contractEndDate) : null}
        />
      </CustomFormControl>
      <CustomFormControl label="Contract End Date">
        <Datetime
          id="contractEndDate"
          inputProps={contractEndDateInputProps}
          closeOnSelect
          className={requiredFieldsForm.includes('contractEndDate') ? 'error' : ''}
          value={form.contractEndDate}
          timeFormat={false}
          dateFormat="YYYY-MM-DD"
          onChange={e => {
            onChangeValue('contractEndDate', e._isAMomentObject ? e.format('YYYY-MM-DD') : e); // eslint-disable-line no-underscore-dangle
          }}
          minDate={form.contractStartDate ? new Date(form.contractStartDate) : null}
        />
      </CustomFormControl>
    </>
  );
};

ContractSection.propTypes = propTypes;

export default ContractSection;
