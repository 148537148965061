import React, { Component } from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import Datetime from '@/components/core/DatePicker';

import NumberSpinnerInput from '../../../form/NumberSpinnerInput/NumberSpinnerInput';

import './InternationalOrderRecurringFields.scss';
import POFormFieldLabel from '../edit-form/POFormFieldLabel';
import { HStack } from '@chakra-ui/react';
import { Checkbox } from '@/components/ui/checkbox';

class InternationalOrderRecurringFields extends Component {
  static propTypes = {
    isInEditMode: PropTypes.bool.isRequired,
    purchaseOrderIndex: PropTypes.number.isRequired,
    validationErrors: PropTypes.shape({
      pos: PropTypes.instanceOf(Object),
      error: PropTypes.bool,
    }),
    onDateChange: PropTypes.func.isRequired,
    onDayChange: PropTypes.func.isRequired,
    dateStart: PropTypes.string.isRequired,
    dateEnd: PropTypes.string.isRequired,
    days: PropTypes.shape({
      mon: PropTypes.number.isRequired,
      tue: PropTypes.number.isRequired,
      wed: PropTypes.number.isRequired,
      thu: PropTypes.number.isRequired,
      fri: PropTypes.number.isRequired,
      sat: PropTypes.number.isRequired,
      sun: PropTypes.number.isRequired,
    }).isRequired,
  };

  static checkForError(validationErrors, purchaseOrderIndex, fieldName) {
    return get(validationErrors, `pos.${purchaseOrderIndex}.errors`, []).includes(fieldName);
  }

  render() {
    const { isInEditMode, validationErrors, purchaseOrderIndex, onDateChange, onDayChange, days, dateStart, dateEnd } =
      this.props;

    const daysList = Object.entries(days);

    return (
      <div className="international-order-recurring-fields">
        <div className="international-order-recurring-fields__dates">
          <div className="international-order-recurring-fields__date">
            <POFormFieldLabel label="Delivery Start Date" htmlFor="start_date" fieldName="recurring_state_date" />
            <Datetime
              id="recurring-start-date"
              disabled={!isInEditMode}
              inputProps={{
                placeholder: 'Recurring Start Date',
                className: 'form-control m-input',
                disabled: !isInEditMode,
              }}
              className={
                InternationalOrderRecurringFields.checkForError(
                  validationErrors,
                  purchaseOrderIndex,
                  'recurring_state_date'
                ) && 'error'
              }
              closeOnSelect
              value={dateStart}
              onChange={e =>
                onDateChange(
                  'Recurring Start Date',
                  'recurringDateStart',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : e // eslint-disable-line no-underscore-dangle
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </div>
          <div className="international-order-recurring-fields__date">
            <POFormFieldLabel label="Delivery End Date" htmlFor="end_date" fieldName="recurringDateEnd" />
            <Datetime
              id="recurring-end-date"
              inputProps={{
                placeholder: 'Recurring End Date',
                className: 'form-control m-input',
                disabled: !isInEditMode,
              }}
              closeOnSelect
              className={
                InternationalOrderRecurringFields.checkForError(
                  validationErrors,
                  purchaseOrderIndex,
                  'recurringDateEnd'
                ) && 'error'
              }
              value={dateEnd}
              onChange={e =>
                onDateChange(
                  'Recurring End Date',
                  'recurringDateEnd',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : e // eslint-disable-line no-underscore-dangle
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </div>
        </div>
        <HStack spacing="5px">
          {daysList.map(day => (
            <div className="international-order-recurring-fields__day" key={`${purchaseOrderIndex}-${day[0]}`}>
              <div
                className={`international-order-recurring-fields__day-content ${
                  day[1] > 0 && 'international-order-recurring-fields__day-content--wide'
                }`}
              >
                <Checkbox
                  className={
                    InternationalOrderRecurringFields.checkForError(validationErrors, purchaseOrderIndex, 'days') &&
                    'error'
                  }
                  colorScheme="actionSecondary"
                  marginTop="22px"
                  marginRight={day[1] >= 1 && '18px'}
                  checked={day[1] >= 1}
                  onChange={() => (day[1] < 1 ? onDayChange(day[0], 1) : onDayChange(day[0], 0))}
                >
                  {startCase(day[0])}
                </Checkbox>
                {day[1] > 0 && (
                  <NumberSpinnerInput
                    className="international-order-recurring-fields__count"
                    showError={false}
                    onChange={count => {
                      onDayChange(day[0], count);
                    }}
                    value={day[1]}
                    name={day[0]}
                  />
                )}
              </div>
            </div>
          ))}
        </HStack>
      </div>
    );
  }
}

export default InternationalOrderRecurringFields;
